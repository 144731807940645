import React, { Component } from "react"
import axios from "axios"
import {Auth} from "aws-amplify"
import { Modal } from "../components/controls/modal"
class TemplateField extends Component {

    constructor(props){
        super(props)
        this.state = {
            fields: [],
            loading: true,
            errormsg: '',
            editing_index: -1,
            editing_name: "",
            editing_pos: -1,
            editing_id: '',
            sendin_request: false,
            field_to_delete: null,
            all_permissions: props.all_permissions,
            permissions: props.permissions
        }
        this.listFields = this.listFields.bind(this)
        this.createField = this.createField.bind(this)
    }

    componentDidMount(){
        Auth.currentSession().then(session=>{
            var headers = {"Authorization":+'Bearer'+ ' '+session.getIdToken().getJwtToken()}
            axios.get(
                `${process.env.REACT_APP_API_URL}/f/template/fields/${this.props.id}`,{headers}
            )
            .then(data => {
                this.setState({
                    fields: data.data.Fields
                })
            })
            .finally(() => this.setState({
                loading: false
            }))
        })
    }

    listFields(){
        return (
            this.state.fields.map((field, index) => (
                <tr key={index}>
                    <th scope="row">{field.Position}</th>
                    <td>{field.Name}</td>
                </tr>
            ))
        )
    }
    async deleteField(field){
        if(field){
            try{
                this.setState({loading:true,field_to_delete:null})
                var headers = {"Authorization":'Bearer'+ ' '+ (await Auth.currentSession()).getIdToken().getJwtToken()}
                var {status} = await axios.delete(`${process.env.REACT_APP_API_URL}/f/template/field/${this.props.id}`,{params:{field_id:field.Id},headers})
                if(status===200){   
                    var response = await axios.get(`${process.env.REACT_APP_API_URL}/f/template/fields/${this.props.id}`,{headers})
                    if(response.status===200){
                        this.setState({loading:false,field_to_delete:null, fields:response.data.Fields})
                    }
                    else {
                        this.setState({loading:false,field_to_delete:null})
                    }

                }
            }
            catch(ex){
                this.setState({loading:false,field_to_delete:null})
            }
        }
    }
    createField(){
        if( this.state.editing_pos !== '' && this.state.editing_name !== '' ){
            this.setState({
                sending_request: true
            })
            Auth.currentSession().then(session=>{
                var token = +'Bearer'+ ' '+ session.getIdToken().getJwtToken()
                let params = {
                    method: this.state.editing_index !== -1 ? 'PUT' : 'POST',
                    url: `${process.env.REACT_APP_API_URL}/f/template/field/${this.props.id}`,
                    data: {
                        Position: this.state.editing_pos,
                        Name: this.state.editing_name
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":token
                    }
                }
                if (this.state.editing_index !== -1) {
                    params["params"] = {
                        "field_id": this.state.editing_id
                    }
                }
                axios(params)
                .then(data => {
                    Auth.currentSession().then(session=>{
                        var headers = {"Authorization": +'Bearer'+ ' '+ session.getIdToken().getJwtToken()}
                        axios.get(
                            `${process.env.REACT_APP_API_URL}/f/template/fields/${this.props.id}`,{headers}
                        )
                        .then(data => {
                            this.setState({
                                fields: data.data.Fields
                            })
                        })
                        .finally(() => this.setState({
                            loading: false
                        }))
                    })
                })
                .catch(err => {
                    this.setState({
                        errormsg: err.response.data.message
                    })
                })
                .finally(() => {
                    this.setState({
                        editing_index: -1,
                        editing_pos: '',
                        editing_name: '',
                        sending_request: false
                    })
                })
            })
        } else {
            
            this.setState({
                errormsg: 'Por favor llena todos los campos *'
            })
        }
    }

    render() {
        return (
            <div>
                <h1 className="text-center">Campos</h1>
                <p className="text-center">Id del template {this.props.id}</p>
                <Modal id="modal" 
                    title="Eliminación de template" 
                    description={`¿Está seguro de eliminar este campo "${this.state.field_to_delete?this.state.field_to_delete.Name:""}"?` }
                    saveMessage="Aceptar"
                    cancelMessage="Cancelar"
                    callback={()=>{this.deleteField(this.state.field_to_delete)}}/>
                <div className="row form-row">
                    <div className="form-group col-md-3">
                        <label htmlFor="position">Posición *</label>
                        <input value={this.state.editing_pos === -1 ? '' : this.state.editing_pos} onChange={(e) => {
                            this.setState({
                                editing_pos: e.target.value
                            })
                        }} type="number" min="0" className="form-control" id="position" />
                    </div>
                    <div className="form-group col-md-9">
                        <label htmlFor="name">Nombre *</label>
                        <input value={this.state.editing_name} onChange={(e) => {
                            this.setState({
                                editing_name: e.target.value
                            })
                        }} type="text" className="form-control" id="name" />
                    </div>
                </div>
                {this.state.errormsg && <div className="alert alert-danger" role="alert">
                    {this.state.errormsg}
                </div>}
                <div className="d-flex justify-content-end"> 
                    {
                        this.state.sending_request ? 
                        (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (<button type="button" className="btn cemaco-button" onClick={this.createField}>{this.state.editing_index !== -1 ? 'Actualizar' : 'Crear'}</button>)
                    }
                </div>

                {this.state.loading ? <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>: ''}

                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th className="w-25" scope="col">Posición</th>
                            <th className="w-50" scope="col">Nombre</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [].concat(this.state.fields)
                            .sort((a, b) => a.Position > b.Position ? 1 : -1)
                            .map((field, index) => 
                                <tr key={index}>
                                    <th scope="row">
                                        {this.editing_index === index ? (
                                            <input type="number" min="0" className="form-control" default={field.Position} onChange={(e) => {
                                                console.log(typeof(e.target.value))
                                                if (e.target.value > 0) {
                                                    this.setState({
                                                        editing_pos: e.target.value
                                                    })
                                                }
                                            }} />
                                        ) : field.Position}
                                    </th>
                                    <td>
                                        {this.editing_index === index ? (
                                            <input type="text" className="form-control" default={field.Name} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({
                                                        editing_name: e.target.value
                                                    })
                                                }
                                            }} />
                                        ) : field.Name}
                                    </td>
                                    <td>
                                        {
                                            this.editing_index !== index ? (
                                                <React.Fragment>
                                                <i className="bi bi-pencil mr-3" onClick={() => {
                                                    this.setState({
                                                        editing_index: index,
                                                        editing_pos: field.Position,
                                                        editing_name: field.Name,
                                                        editing_id: field.Id
                                                    })
                                                }} role="button">Editar</i>
                                                {
                                                    this.state.permissions["ELIMINAR_TEMPLATE_FIELD"] || this.state.all_permissions?
                                                    <i className="bi bi-trash" onClick={() => {
                                                        this.setState({
                                                            field_to_delete:field
                                                        })
                                                    }} role="button" data-toggle="modal" data-target="#modal">Eliminar</i>:null
                                                }
                                               </React.Fragment> 
                                            ) : (<i className="bi bi-pencil mr-3" role="button">Actualizar</i>)
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                    </table>
            </div>
        )
    }
}

export default TemplateField