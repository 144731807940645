import React from 'react'

const Modal = (props) => {
    const {id, title, description, saveMessage, cancelMessage, callback} = props
    const defaultHandle =(params)=>{
        if(callback)
        {
           callback(params)
        }
    }
    return (
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {props.children?props.children:description}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn cemaco-button mr-auto" data-dismiss="modal">{cancelMessage?cancelMessage:"Cancel"}</button>
                    <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={defaultHandle}>{saveMessage?saveMessage:"Save changes"}</button>
                </div>
                </div>
            </div>
        </div>
  )
}
export { Modal }