import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter as Router,
    Route,
    BrowserRouter,
    Switch,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import "react-widgets/styles.css";
import Masivos from "./message/Masivos";
import Template from "./template/Template";
import TemplateField from "./template_field/TemplateField";
import Structure from "./Structure";
import Logs from "./logs/Logs";
import Individual from "./message/Individual";
import Login from "./authentication/Login";
import Amplify from "aws-amplify";
import ForgotPassword from "./authentication/ForgotPassword";
import AuthStructure from "./AuthStructure";
import Telefono from "./telefono/Telefono";
import Suplemento from "./suplemento/Suplemento";
import Usuarios from "./usuarios/usuarios"
import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Archivos from "./suplemento/Archivos";
import EditarUsuario from "./usuarios/editar";
import CrearUsuario from "./usuarios/create";
import LogsMenajesProgramados from "./logs/LogsMensajesProgramados"
import GenerateToken from "./user/GenerateToken";

FilePond.registerPlugin(FilePondPluginFileValidateType);

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH",
    },
});

/**
 * Las rutas "/", "/enviar-mensajes" sirven para enviar mensajes, también son la home
 * La ruta "/template" es para crear un template nuevo
 * La ruta "/template/:template_id" es para actualizar un template existente y debe verificar que el template exista
 * La ruta "/template/:template_id/field" es para ver, crear y actualizar los template fields de un template
 */

ReactDOM.render(
    <BrowserRouter>
        <Router>
            <Switch>
                <Route exact path="/usuarios">
                   <Structure container="container">
                        <Usuarios />
                    </Structure>
                </Route>
                <Route exact path="/login">
                    <AuthStructure>
                        <Login />
                    </AuthStructure>
                </Route>
                <Route exact path="/recover-account">
                    <AuthStructure>
                        <ForgotPassword />
                    </AuthStructure>
                </Route>
                <Route exact path={["/", "/enviar-mensajes"]}>
                    <Structure container="container">
                        <Masivos />
                    </Structure>
                </Route>
                <Route exact path={["/mensaje-individual"]}>
                    <Structure container="container">
                        <Individual />
                    </Structure>
                </Route>
                <Route exact path="/template">
                    <Structure container="container">
                        <Template key="1" id="" update={false} />
                    </Structure>
                </Route>
                <Route exact path="/logs">
                    <Structure container="container-fluid">
                        <Logs />
                    </Structure>
                </Route>
                <Route exact path="/logsmensajesprogramados">
                   <Structure container="container">
                        <LogsMenajesProgramados/>
                    </Structure>
                </Route>
                <Route exact path="/suplemento">
                    <Structure container="container">
                        <Suplemento />
                    </Structure>
                </Route>
                <Route exact path="/archivos">
                    <Structure container="container">
                        <Archivos />
                    </Structure>
                </Route>
                <Route exact path="/telefono">
                    <Structure container="container">
                        <Telefono />
                    </Structure>
                </Route>
                <Route exact path="/usuario/editar/:id">
                    <Structure container="container">
                        <EditarUsuario rolUsuario={'mivariable'}/>
                    </Structure>
                </Route>

                <Route exact path="/usuario/create">
                    <Structure container="container">
                        <CrearUsuario/>
                    </Structure>
                </Route>
                <Route
                    exact
                    path="/template/:template_id"
                    render={(props) => {
                        //console.log(props);
                        return (
                            <Structure container="container">
                                <Template
                                    key={props.match.params.template_id}
                                    id={props.match.params.template_id}
                                    update={true}
                                />
                            </Structure>
                        );
                    }}
                />
                <Route
                    exact
                    path={[
                        "/template/:template_id/field",
                        "/template/:template_id/field/",
                    ]}
                    render={(props) => {
                        return (
                            <Structure container="container">
                                <TemplateField
                                    id={props.match.params.template_id}
                                />
                            </Structure>
                        );
                    }}
                />
                <Route exact path="/auth/token">
                    <Structure container="container">
                        <GenerateToken/>
                    </Structure>
                </Route>
            </Switch>
        </Router>
    </BrowserRouter>,
    document.getElementById("root")
);

reportWebVitals();
