import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CemacoLogo from "../cemaco-logo";
import { Auth } from "aws-amplify";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            nombre: "",
            oldPassword: "",
            newPassword: "",
            checkPassword: "",
            oldPasswordHasError: false,
            newPasswordHasError: false,
            checkPasswordHasError: false,
            nombreHasError: false,
            oldPasswordInvalidText: "",
            newPasswordInvalidText: "",
            checkPasswordInvalidText: "",
            nombreInvalidText: "",
            usernameHasError: false,
            passwordHasError: false,
            usernameInvalidText: "",
            passwordInvalidText: "",
            isLoadingLogin: false,
            isLoadingReset: false,
            showNotification: false,
            notificationIsSuccess: false,
            notificationMessage: "",
            loginButtonDisabled: false,
            shouldRedirect: false,
            hasChallengue: false,
            resetButtonDisabled: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
        this.reset = this.reset.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doReset = this.doReset.bind(this);
    }

    /**
     *
     * @param {*} e Evento del boton
     */
    handleChange(e) {
        if (!this.state.hasChallengue) {
            this.setState({
                username:
                    e.target.id === "username"
                        ? e.target.value
                        : this.state.username,
                password:
                    e.target.id === "password"
                        ? e.target.value
                        : this.state.password,
            });
            if (e.target.id === "username") {
                this.setState({
                    usernameHasError: e.target.value.length === 0,
                    usernameInvalidText:
                        e.target.value.length === 0
                            ? "El nombre de usuario es requerido"
                            : "",
                    loginButtonDisabled: e.target.value.length === 0,
                });
            } else if (e.target.id === "password") {
                this.setState({
                    passwordHasError: e.target.value.length === 0,
                    passwordInvalidText:
                        e.target.value.length === 0
                            ? "La contraseña es requerida"
                            : "",
                    loginButtonDisabled: e.target.value.length === 0,
                });
            }
        } else {
            this.setState({
                oldPassword:
                    e.target.id === "old-password"
                        ? e.target.value
                        : this.state.oldPassword,
                newPassword:
                    e.target.id === "new-password"
                        ? e.target.value
                        : this.state.newPassword,
                checkPassword:
                    e.target.id === "check-new-password"
                        ? e.target.value
                        : this.state.checkPassword,
                nombre:
                    e.target.id === "nombre"
                        ? e.target.value
                        : this.state.nombre,
            });
            if (e.target.id === "old-password") {
                this.setState({
                    oldPasswordHasError: e.target.value.length === 0,
                    oldPasswordInvalidText:
                        e.target.value.length === 0
                            ? "La contraseña anterior es requerida"
                            : "",
                    resetButtonDisabled: e.target.value.length === 0,
                });
            } else if (e.target.id === "new-password") {
                this.setState({
                    newPasswordHasError: e.target.value.length === 0,
                    newPasswordInvalidText:
                        e.target.value.length === 0
                            ? "La contraseña nueva es requerida"
                            : "",
                    resetButtonDisabled: e.target.value.length === 0,
                });
            } else if (e.target.id === "check-new-password") {
                this.setState({
                    checkPasswordHasError: e.target.value.length === 0,
                    checkPasswordInvalidText:
                        e.target.value.length === 0
                            ? "Debes volver a escribir tu contraseña nueva"
                            : "",
                    resetButtonDisabled: e.target.value.length === 0,
                });
            } else if (e.target.id === "nombre") {
                this.setState({
                    nombreHasError: e.target.value.length === 0,
                    nombreInvalidText:
                        e.target.value.length === 0
                            ? "El nombre es requerido"
                            : "",
                    resetButtonDisabled: e.target.value.length === 0,
                });
            }
        }
    }

    async login(e) {
        this.setState({
            showNotification: false,
            isLoadingLogin: true,
        });
        if (
            this.state.username.length === 0 ||
            this.state.password.length === 0
        ) {
            this.setState({
                passwordHasError: this.state.password.length === 0,
                passwordInvalidText:
                    this.state.password.length === 0
                        ? "La contraseña es requerida"
                        : "",
                usernameHasError: this.state.username.length === 0,
                usernameInvalidText:
                    this.state.username.length === 0
                        ? "El nombre de usuario es requerido"
                        : "",
                loginButtonDisabled:
                    this.state.password.length === 0 ||
                    this.state.username.length === 0,
                notificationIsSuccess: false,
                notificationMessage:
                    "El nombre de usuario y la contraseña son requeridos",
                showNotification: true,
                isLoadingLogin: false,
            });
        } else {
            try {
                let user = await Auth.signIn(
                  
                    this.state.username,
                    this.state.password
                );

                let shouldChange = user.challengeName;
                if (shouldChange === "NEW_PASSWORD_REQUIRED") {
                    this.setState({
                        hasChallengue: true,
                    });
                } else {
                    this.setState({
                        shouldRedirect: true,
                        isLoadingLogin: false,
                    });
                }
            } catch (e) {
                this.setState({
                    notificationIsSuccess: false,
                    showNotification: true,
                    notificationMessage: e.message,
                    isLoadingLogin: false,
                });
            }
        }
    }

    wait(secs) {
        return new Promise((resolve) => {
            setTimeout(resolve, secs * 1000);
        });
    }

    reset(e) {
        this.setState({
            showNotification: false,
            isLoadingReset: true,
        });
        if (
            this.state.oldPassword.length === 0 ||
            this.state.newPassword.length === 0 ||
            this.state.checkPassword.length === 0 ||
            this.state.nombre.length === 0
        ) {
            this.setState({
                oldPasswordHasError: this.state.oldPassword.length === 0,
                oldPasswordInvalidText:
                    this.state.oldPassword.length === 0
                        ? "La contraseña anterior es requerida"
                        : "",
                nombreHasError: this.state.nombre.length === 0,
                nombreInvalidText:
                    this.state.nombre.length === 0
                        ? "El nombre es requerido"
                        : "",
                newPasswordHasError: this.state.newPassword.length === 0,
                newPasswordInvalidText:
                    this.state.newPassword.length === 0
                        ? "La contraseña nueva es requerida"
                        : "",
                checkPasswordHasError: this.state.checkPassword.length === 0,
                checkPasswordInvalidText:
                    this.state.checkPassword.length === 0
                        ? "Debes volver a escribir tu contraseña nueva"
                        : "",
                resetButtonDisabled:
                    this.state.oldPassword.length === 0 ||
                    this.state.newPassword.length === 0 ||
                    this.state.checkPassword.length === 0,
                notificationIsSuccess: false,
                notificationMessage: "Todos los campos son requeridos",
                showNotification: true,
            });
        } else {
            try {
                Auth.signIn(this.state.username, this.state.password).then(
                    (user) => {

                        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            Auth.completeNewPassword(
                                user,
                                this.state.newPassword,
                                {
                                    name: this.state.nombre,
                                }
                            )
                                .then((user) => {
                                    this.setState({
                                        notificationMessage:
                                            "La contraseña se actualizo correctamente",
                                        showNotification: true,
                                        notificationIsSuccess: true,
                                    });
                                    this.wait(2);
                                    this.setState({
                                        shouldRedirect: true,
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        notificationIsSuccess: false,
                                        showNotification: true,
                                        notificationMessage: error.message,
                                    });
                                });
                        }
                    }
                );
            } catch (e) {
                this.setState({
                    notificationIsSuccess: false,
                    showNotification: true,
                    notificationMessage: e.message,
                });
            }
        }
        this.setState({
            isLoadingReset: false,
        });
    }

    doLogin(event) {
        if (event.key === "Enter") {
            if (!this.state.isLoadingLogin) this.login();
        }
    }

    doReset(event) {
        if (event.key === "Enter") {
            if (!this.state.isLoadingReset) this.reset();
        }
    }

    render() {
        if (this.state.shouldRedirect) return <Redirect to="/" push />;
        if (this.state.hasChallengue)
            return (
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="card bg-light text-center">
                                <div className="card-body">
                                    <CemacoLogo textColor="#101e8e" />
                                    <h5 className="card-title mb-3 mt-2">
                                        <small className="text-muted">
                                            Completa tu cuenta
                                        </small>
                                    </h5>
                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div
                                                className={
                                                    "alert " +
                                                    (this.state
                                                        .notificationIsSuccess
                                                        ? "alert-success"
                                                        : "alert-danger") +
                                                    " " +
                                                    (this.state.showNotification
                                                        ? ""
                                                        : "d-none")
                                                }
                                            >
                                                {this.state.notificationMessage}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div className="form-group mb-3">
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .nombreHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="nombre"
                                                    onChange={this.handleChange}
                                                    value={this.state.nombre}
                                                    placeholder="Nombre"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .nombreHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .nombreInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <input
                                                    type="password"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .oldPasswordHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="old-password"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.doReset}
                                                    value={
                                                        this.state.oldPassword
                                                    }
                                                    placeholder="Contraseña anterior"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .oldPasswordHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .oldPasswordInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <input
                                                    type="password"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .newPasswordHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="new-password"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.doReset}
                                                    value={
                                                        this.state.newPassword
                                                    }
                                                    placeholder="Contraseña nueva"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .newPasswordHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .newPasswordInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <input
                                                    type="password"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .checkPasswordHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="check-new-password"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.doReset}
                                                    value={
                                                        this.state.checkPassword
                                                    }
                                                    placeholder="Verifica tu contraseña"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .checkPasswordHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .checkPasswordInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <button
                                                        className={
                                                            "btn btn-primary cemaco-button " +
                                                            (this.state
                                                                .resetButtonDisabled
                                                                ? "disabled"
                                                                : "")
                                                        }
                                                        type="submit"
                                                        onClick={this.reset}
                                                    >
                                                        {this.isLoadingReset ? (
                                                            <span
                                                                className="spinner-grow spinner-grow-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            "Cambiar contraseña"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="card bg-light text-center">
                                <div className="card-body">
                                    <CemacoLogo textColor="#101e8e" />
                                    <h5 className="card-title mb-3 mt-2">
                                        <small className="text-muted">
                                            Iniciar sesion
                                        </small>
                                    </h5>
                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div
                                                className={
                                                    "alert " +
                                                    (this.state
                                                        .notificationIsSuccess
                                                        ? "alert-success"
                                                        : "alert-danger") +
                                                    " " +
                                                    (this.state.showNotification
                                                        ? ""
                                                        : "d-none")
                                                }
                                            >
                                                {this.state.notificationMessage}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div className="form-group mb-3">
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .usernameHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="username"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.doLogin}
                                                    value={this.state.username}
                                                    placeholder="Nombre de usuario"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .usernameHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .usernameInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <input
                                                    type="password"
                                                    className={
                                                        "form-control " +
                                                        (this.state
                                                            .passwordHasError
                                                            ? "is-invalid"
                                                            : "")
                                                    }
                                                    id="password"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.doLogin}
                                                    value={this.state.password}
                                                    placeholder="Contraseña"
                                                />
                                                <div
                                                    className={
                                                        "invalid-feedback " +
                                                        (!this.state
                                                            .passwordHasError
                                                            ? "d-none"
                                                            : "")
                                                    }
                                                >
                                                    {
                                                        this.state
                                                            .passwordInvalidText
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <button
                                                        className={
                                                            "btn btn-primary cemaco-button " +
                                                            (this.state
                                                                .loginButtonDisabled ||
                                                            this.state
                                                                .isLoadingLogin
                                                                ? "disabled"
                                                                : "")
                                                        }
                                                        type="submit"
                                                        onClick={this.login}
                                                    >
                                                        {this.state
                                                            .isLoadingLogin ? (
                                                            <span
                                                                className="spinner-grow spinner-grow-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            "Iniciar sesion"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <Link
                                                        to="recover-account"
                                                        className="btn btn-link"
                                                    >
                                                        Olvide mi contraseña
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

export default Login;
