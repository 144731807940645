import React, { Component } from "react";

class CemacoLogo extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 18" className="site-brand__svg"><title>CEMACO</title><path d="M8.0606,11.5412a1.367,1.367,0,0,0,1.2879,1.3146,2.1082,2.1082,0,0,0,2.3038-2.3578A3.048,3.048,0,0,0,8.4907,7.8021a3.7715,3.7715,0,0,0-3.9416,3.9093c0,2.6737,2.291,4.99,6.9013,4.99h2.9308V7.71L7.9477,2.5075,1.7034,7.5222V18.4508H0V6.5754L7.9477.33,16.0851,6.859V18.4508H10.3143c-4.2715,0-7.7034-2.6781-7.7034-6.7393a5.6254,5.6254,0,0,1,5.88-5.7146,4.6288,4.6288,0,0,1,4.8562,4.5012,3.693,3.693,0,0,1-3.9984,4.0256,3.0689,3.0689,0,0,1-3.09-3.062,2.3266,2.3266,0,0,1,2.232-2.36c1.0481,0,1.7518.61,1.7518,1.3441a.86.86,0,0,1-.9079.9207,1.1452,1.1452,0,0,1-.9085-.57.8638.8638,0,0,0-.3655.7449" transform="translate(0 -0.33)" style={{
                "fill": "#93d500"
            }} className="isotype"></path><polygon points="30.443 12.051 33.204 12.051 33.204 9.789 30.443 9.789 30.443 7.934 34.311 7.934 33.844 5.672 27.792 5.672 27.792 16.369 33.983 16.369 34.638 14.111 30.443 14.111 30.443 12.051" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></polygon><polygon points="44.658 5.423 41.179 11.974 37.81 5.423 37.778 5.423 34.846 16.25 37.562 16.622 38.481 11.49 38.511 11.49 41.069 16.622 41.101 16.622 43.909 11.475 43.939 11.475 44.859 16.622 47.527 16.25 44.689 5.423 44.658 5.423" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></polygon><path d="M64.5226,14.5181a2.9858,2.9858,0,0,1-3.0894-3.1967,2.9841,2.9841,0,0,1,3.0894-3.1349,3.11,3.11,0,0,1,1.6312.5542L65.53,5.9044a4.625,4.625,0,0,0-1.2123-.1519,5.4177,5.4177,0,0,0-5.5355,5.6306,5.3015,5.3015,0,0,0,5.5522,5.5684,6.6873,6.6873,0,0,0,1.8715-.3862,2.5634,2.5634,0,0,0,.4562-.27l.1046-2.7416s-.1019.0773-.232.1686a3.6779,3.6779,0,0,1-2.0122.7955" transform="translate(0 -0.33)" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></path><path d="M24.4006,14.5181a2.9851,2.9851,0,0,1-3.0888-3.1967,2.9845,2.9845,0,0,1,3.0888-3.1349,3.1162,3.1162,0,0,1,1.6328.5542l-.6253-2.8362a4.6245,4.6245,0,0,0-1.2111-.1519A5.4186,5.4186,0,0,0,18.66,11.3831a5.3024,5.3024,0,0,0,5.5533,5.5684,6.7122,6.7122,0,0,0,1.8714-.3862,2.7944,2.7944,0,0,0,.4562-.27l.1035-2.7416s-.1018.0773-.2331.1686a3.6745,3.6745,0,0,1-2.0111.7955" transform="translate(0 -0.33)" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></path><path d="M73.29,5.7525a5.6581,5.6581,0,0,0-5.7079,5.6006,5.71,5.71,0,0,0,11.4181,0,5.659,5.659,0,0,0-5.71-5.6006m0,8.7656a3.1677,3.1677,0,1,1,3.0576-3.165,3.1866,3.1866,0,0,1-3.0576,3.165" transform="translate(0 -0.33)" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></path><path d="M52.1548,12.6772l1.4192-2.62,1.4192,2.62ZM53.574,5.7525,47.8661,16.41l2.5736.5419L51.5145,14.66h4.0723l1.0916,2.2916,2.6987-.5419L53.6052,5.7525Z" transform="translate(0 -0.33)" className='d-md-block' style={{
                "fill": this.props.textColor
            }}></path>
            </svg>
        )
    }
}

export default CemacoLogo