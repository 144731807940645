import React from 'react'
import axios from 'axios'
import {Auth} from 'aws-amplify'

const GenerateToken = () => {

    const [is_notification, setIs_notification]=React.useState(false)
    const [is_loading,set_Is_Loading] = React.useState(false)
    const [days,setDays] = React.useState("")
    const [years,setYears] = React.useState("")
    const [hours,setHours] = React.useState("")
    const [token,setToken] = React.useState("")
    const [notification_message,setNotification_message] = React.useState(null)

    const getToken=async ()=>{
        try{
            setIs_notification(false)
            set_Is_Loading(true)
            setToken("")
            var headers = {"Authorization":(await Auth.currentSession()).getIdToken().getJwtToken()}
            var url = `${process.env.REACT_APP_API_URL}/f/auth/token`
            if(days || years || hours){
                url+=`?years=${years??0}&days=${days??0}&hours=${hours??0}`
            }
            var response = await axios.post(url,{},{headers})
            if(response.status===200){
                setToken(response.data.Token)
            }
        }
        catch(ex){
            if(ex.isAxiosError){
                setIs_notification(true)
                setNotification_message(
                    <div className="alert alert-danger" role="alert">
                        {ex.response?ex.response.data.message:"Ocurrió un error al contactar con el navegador"}
                    </div> )
            }
        }
        finally{
            set_Is_Loading(false)
        }
    }

    return (
        <React.Fragment>
            <h1 className="text-center mt-4 mb-1 pt-4 pb-5">Generar Token de Acceso</h1>
            {is_notification ? (
                <div className="row mt-3 mb-3">
                    <div className="col-md-12">
                        {notification_message}
                    </div>
                </div>
            ) : null}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div
                        className="alert alert-info"
                        role="alert"
                    >
                        <p>
                            Si no se especifican valores de duración, la duración del token será de 1 año (365 días) por defecto
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-3">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Años</span>
                        </div>
                        <input 
                            type="number"
                            className={
                                "form-control "+(
                                    (parseInt(years)>3 || parseInt(years)<0)?"is-invalid":""
                                )
                            }
                            id="yearsId"
                            value = {years}
                            onChange={(e)=>setYears(e.target.value)}
                            placeholder="Max. 3"/>
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Días</span>
                        </div>
                        <input 
                            type="number"
                            className={
                                "form-control "+(
                                    (parseInt(days)>365 || parseInt(days)<0)?"is-invalid":""
                                )
                            }
                            id="diasId"
                            value = {days}
                            onChange={(e)=>setDays(e.target.value)}
                            placeholder="Max. 365"/>
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Horas</span>
                            </div>
                        <input 
                            type="number"
                            className={
                                "form-control "+(
                                    (parseInt(hours)>24 || parseInt(hours)<0)?"is-invalid":""
                                )
                            }
                            id="horasId"
                            value={hours}
                            onChange={(e)=>setHours(e.target.value)}
                            placeholder="Max. 24"/>
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <button
                        onClick={getToken}
                        type="button"
                        className="btn btn-primary mb-2 float-right">
                        {is_loading?
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="sr-only">Cargando...</span>
                        </div>:"Generar"}
                    </button>
                </div>
            </div>
            {
            (token)?
            <div className="row mt-1">
                <div className="form-group col-md-12">
                    <label htmlFor="textareaToken">Token</label>
                    <button
                        onClick={(e)=>{if(token){navigator.clipboard.writeText(token)}}}
                        data-toggle="tooltip" data-placement="top" title="Copiar al portapapeles"
                        type="button"
                        className="btn btn-sm btn-light float-right mb-2">
                        <i className="bi bi-clipboard"></i>
                    </button>
                    <textarea className="form-control" id="textareaToken" readOnly rows="10" value={token}></textarea>
                </div></div>:null
            }
        </React.Fragment>
    )
}

export default GenerateToken