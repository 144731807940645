import axios from "axios";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {Auth} from "aws-amplify";
import {Modal} from "../components/controls/modal";

class Template extends Component {
    constructor(props) {
        super(props);
        let prop_update = props.id === "";
        this.state = {
            template_id: this.props.id,
            is_update: prop_update,
            template_info: [],
            loading_template_info: prop_update,
            is_error: false,
            is_notification: false,
            notification_message: "",
            loading: true,
            template_values: {
                Id: "",
                Name: "",
                Description: "",
                Category: "",
                HasFile: false,
                FileField: "",
                FileType: "document",
            },
            id_validation: !prop_update,
            name_validation: !prop_update,
            description_validation: !prop_update,
            file_field_validation: !prop_update,
            file_type_validation: !prop_update,
            category_validation: !prop_update,
            return_redirect: false,

            getting_data: false,
            loader: (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ),
            template_to_delete : null,
            permissions:props.permissions,
            all_permissions:props.all_permissions
        };
        this.loadTemplateInfo = this.loadTemplateInfo.bind(this);
        this.renderAllList = this.renderAllList.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);
    }

    componentDidMount() {
        this.loadTemplateInfo();
        this.loadTemplates();
    }
    async deleteTemplate(template){
        if(template){
            try{
                this.setState({loading:true,template_to_delete:null})
                var headers = {"Authorization":'Bearer'+' '+(await Auth.currentSession()).getIdToken().getJwtToken()}
                var {status} = await axios.delete(`${process.env.REACT_APP_API_URL}/f/template/${template.Id}`,{headers})
                if(status===200){
                    this.loadTemplates()
                }
            }catch(ex){
                if(ex.isAxiosError)
                {

                }
                this.setState({loading:false, template_to_delete:null})
            }
        }
    }

    loadTemplateInfo() {
        if (this.props.update) {
            this.setState({
                template_info_loaded: true,
            });
            Auth.currentSession().then(session=>{
                var token = session.getIdToken().getJwtToken()

               
                var headers = {"Authorization": 'Bearer' + ' ' + token}
                
                axios
                    .get(
                        `${process.env.REACT_APP_API_URL}/f/template/${this.props.id}`,{headers}
                    )
                    .then((data) => {
                      
                        this.setState({
                            is_notification: false,
                            template_info: data.data,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            is_error: true,
                            is_notification: true,
                            notification_message: (
                                <div className="alert alert-danger" role="alert">
                                    {error.response.data.message}
                                </div>
                            ),
                        });
                    })
                    .finally(() => {
                        this.setState((prevState) => {
                            let template_values = Object.assign(
                                {},
                                prevState.template_values
                            );
                            template_values.Id = this.state.template_info.Id;
                            template_values.Name = this.state.template_info.Name;
                            template_values.FileField =
                                this.state.template_info.FileField;
                            template_values.HasFile =
                                template_values.FileField !== "";
                            template_values.FileType =
                                this.state.template_info.FileType === ""
                                    ? "document"
                                    : this.state.template_info.FileType;
                            template_values.Description =
                                this.state.template_info.Description;
                            template_values.Category =
                                this.state.template_info.Category;
                            return { template_values };
                        });
                        this.setState({
                            loading: false,
                            getting_data: false,
                            templates_loaded: true,
                        });
                    });
                })
        }
    }

    loadTemplates() {
        this.setState({
            getting_data: true,
        });
        Auth.currentSession().then(session=>{
            var headers = {"Authorization": "Bearer" + ' ' +session.getIdToken().getJwtToken()}
            
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/template?limit=500`,{headers})
                .then((data) => {
                    this.setState({
                        is_notification: false,
                        template_info: data.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        is_error: true,
                        is_notification: true,
                        notification_message: (
                            <div className="alert alert-danger" role="alert">
                                {error.response.data.message}
                            </div>
                        ),
                    });
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                        getting_data: false,
                        templates_loaded: true,
                    });
                });
            })
    }

    renderAllList() {
        try {
            return this.state.template_info.templates.map((template, index) => (
              
                <div className="col-md-6" key={index}>
                    <div className="shadow p-5 mb-5 bg-white rounded position-relative">
                        <div className="float-right">
                            <Link to={(location) => `/template/${template.Id}`}>
                                <i className="bi bi-pencil mr-3"></i>
                            </Link>
                            <Link
                                to={(location) =>
                                    `/template/${template.Id}/field`
                                }
                            >
                                <i className="bi bi-plus-circle-fill mr-3"></i>
                            </Link>
                            {
                                this.state.permissions["ELIMINAR_TEMPLATE"] || this.state.all_permissions?
                                <Link to={"#"} data-toggle="modal" data-target="#modal" onClick={(e)=>{this.setState({template_to_delete:template});}}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>:null
                            }
                        </div>
                        <h5>{template.Name}</h5>
                        <p>{template.Description}</p>
                        <p>
                            <strong>Id:</strong> {template.Id}
                        </p>
                        <p>
                            <strong>Categoria:</strong> {template.Category}
                        </p>
                    </div>
                </div>
            ));
        } catch (e) {
            return this.state.loader;
        }
    }
    guardarCategoria(categoria)
    {   
        let  data = {categoria:categoria}
        Auth.currentSession().then(session=>{
            var headers = {"Authorization": 'Bearer'+ ' ' + session.getIdToken().getJwtToken()}
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/f/categorias/guardar`,
                     data,{headers}
                )
                .then((data) => {
                        console.log(data)
                    });
                }).catch((error)=>{
                    console.log(error)
                })
    }

    render() {
        if (this.state.return_redirect) return <Redirect to="/template" push />;
        if (this.state.is_error) return <>{this.state.notification_message}</>;

        return (
            <div>
                <h1 className="text-center mt-4 mb-5 pt-4 pb-5">Templates</h1>

                <Modal id="modal" 
                    title="Eliminación de template" 
                    saveMessage="Aceptar"
                    cancelMessage="Cancelar"
                    callback={()=>{this.deleteTemplate(this.state.template_to_delete)}}>
                        <React.Fragment>¿Está seguro de eliminar el template </React.Fragment>
                        <React.Fragment><strong>{this.state.template_to_delete?this.state.template_to_delete.Id:""}</strong></React.Fragment>
                        <React.Fragment>?</React.Fragment>
                </Modal>
                {this.state.is_notification ? (
                    <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                            {this.state.notification_message}
                        </div>
                    </div>
                ) : null}
                <div className="alert alert-info">
                    <p>Por favor también valida que el ID en Freshchat no contenga ningún carácter especial</p>
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="templateId">Id del template</label>
                        <input
                            type="text"
                            onChange={(val) => {
                                this.setState({
                                    id_validation: true,
                                });
                                this.setState((prevState) => {
                                    let template_values = Object.assign(
                                        {},
                                        prevState.template_values
                                    );
                                    template_values.Id = val.target.value;
                                    return { template_values };
                                });
                            }}
                            value={this.state.template_values.Id}
                            className={
                                "form-control" +
                                (this.state.template_values.Id.length === 0 &&
                                this.state.id_validation
                                    ? " is-invalid"
                                    : "")
                            }
                            id="templateId"
                            placeholder="Ej. confirmacion_pago_navidad1"
                            disabled={this.props.id !== ""}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="nombre">Nombre</label>
                        <input
                            type="text"
                            onChange={(val) => {
                                this.setState({
                                    name_validation: true,
                                });
                                this.setState((prevState) => {
                                    let template_values = Object.assign(
                                        {},
                                        prevState.template_values
                                    );
                                    template_values.Name = val.target.value;
                                    return { template_values };
                                });
                            }}
                            value={this.state.template_values.Name}
                            className={
                                "form-control" +
                                (this.state.template_values.Name.length === 0 &&
                                this.state.name_validation
                                    ? " is-invalid"
                                    : "")
                            }
                            id="nombre"
                            placeholder="Ej. Confirmación de pago navidad"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="categoria">Categoria</label>
                        <input
                            type="text"
                            onChange={(val) => {
                                this.setState({
                                    category_validation: true,
                                });
                                this.setState((prevState) => {
                                    let template_values = Object.assign(
                                        {},
                                        prevState.template_values
                                    );
                                    template_values.Category = val.target.value;
                                    return { template_values };
                                });
                            }}
                            value={this.state.template_values.Category}
                            className={
                                "form-control" +
                                (this.state.template_values.Category.length ===
                                    0 && this.state.category_validation
                                    ? " is-invalid"
                                    : "")
                            }
                            id="categoria"
                            placeholder="Ej. Call Center"
                        />
                    </div>
                    <div className="col-md-12 form-group">
                        <label htmlFor="descripcion">
                            Descripcion del template
                        </label>
                        <input
                            type="text"
                            onChange={(val) => {
                                this.setState({
                                    description_validation: true,
                                });
                                this.setState((prevState) => {
                                    let template_values = Object.assign(
                                        {},
                                        prevState.template_values
                                    );
                                    template_values.Description =
                                        val.target.value;
                                    return { template_values };
                                });
                            }}
                            value={
                                this.state.template_values.Description
                            }
                            className={
                                "form-control" +
                                (this.state.template_values.Description
                                    .length === 0 &&
                                this.state.description_validation
                                    ? " is-invalid"
                                    : "")
                            }
                            id="descripcion"
                            placeholder="Ej. Envía mensajes con la confimarción de pago..."
                        />
                    </div>
                    <div className="col-md-4 form-group form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={this.state.template_values.HasFile}
                            onChange={(val) => {
                                this.setState((prevState) => {
                                    let template_values = Object.assign(
                                        {},
                                        prevState.template_values
                                    );
                                    template_values.HasFile =
                                        val.target.checked;
                                    return { template_values };
                                });
                            }}
                        />
                        <label className="form-check-label">
                            ¿Tiene attachment?
                        </label>
                    </div>
                    {this.state.template_values.HasFile ? (
                        <>
                            <div className="col-md-4 form-group">
                                <label htmlFor="file-field">
                                    Nombre del campo que contendra el link
                                </label>
                                <input
                                    type="text"
                                    value={
                                        this.state.template_values.FileField
                                    }
                                    className={
                                        "form-control" +
                                        (this.state.template_values.FileField
                                            .length === 0 &&
                                        this.state.file_field_validation
                                            ? " is-invalid"
                                            : "")
                                    }
                                    onChange={(val) => {
                                        this.setState({
                                            file_field_validation: true,
                                        });
                                        this.setState((prevState) => {
                                            let template_values = Object.assign(
                                                {},
                                                prevState.template_values
                                            );
                                            template_values.FileField =
                                                val.target.value;
                                            return { template_values };
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <label htmlFor="file-type">
                                    Tipo de archivo que contiene el link
                                </label>
                                <select
                                    className="form-control form-control"
                                    value={
                                        this.state.template_values.FileType
                                    }
                                    onChange={(val) => {
                                        //console.log(val.target.value);
                                        this.setState({
                                            file_type_validation: true,
                                        });
                                        this.setState((prevState) => {
                                            let template_values = Object.assign(
                                                {},
                                                prevState.template_values
                                            );
                                            template_values.FileType =
                                                val.target.value;
                                            return { template_values };
                                        });
                                    }}
                                >
                                    <option>document</option>
                                    <option>image</option>
                                    <option>video</option>
                                </select>
                            </div>
                        </>
                    ) : null}
                </div>
                <button
                    type="button"
                    className="btn btn-primary float-right"
                    disabled={
                        this.state.template_values.Id.length === 0 ||
                        this.state.template_values.Name.length === 0 ||
                        this.state.template_values.Description.length === 0
                    }
                    onClick={() => {
                        if (this.props.id !== "") {
                            let body_to_send = {
                                Description:
                                    this.state.template_values.Description,
                                Name: this.state.template_values.Name,
                                Category: this.state.template_values.Category,
                            };
                            if (this.state.template_values.HasFile) {
                                body_to_send["FileField"] =
                                    this.state.template_values.FileField;
                                body_to_send["FileType"] =
                                    this.state.template_values.FileType;
                            }
                            Auth.currentSession().then(session=>{
                    
                                var headers={"Authorization": 'Bearer' + ' ' + session.getIdToken().getJwtToken()}
                                axios
                                    .put(
                                        `${process.env.REACT_APP_API_URL}/f/template/${this.props.id}`,
                                        body_to_send,{headers}
                                    )
                                    .then((data) => {
                                        this.setState({
                                            return_redirect: true,
                                            is_notification: true,
                                            notification_message: (
                                                <div
                                                    className="alert alert-success"
                                                    role="alert"
                                                >
                                                    {data.data.message}
                                                </div>
                                            ),
                                        });
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            is_notification: true,
                                            notification_message: (
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    {error.response.data.message}
                                                </div>
                                            ),
                                        });
                                    });
                                })
                        } else {
                            let template_id = this.state.template_values.Id;
                            let idcaracterestemplate= template_id.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
                            let body_to_send = {
                                Id: idcaracterestemplate,
                                Name: this.state.template_values.Name,
                                Description:
                                    this.state.template_values.Description,
                                Category: this.state.template_values.Category,
                            };
                            if (this.state.template_values.HasFile) {
                                body_to_send["FileField"] =
                                    this.state.template_values.FileField;
                                body_to_send["FileType"] =
                                    this.state.template_values.FileType;
                            }
                            Auth.currentSession().then(session=>{

                                var headers = {"Authorization": 'Bearer'+ ' ' + session.getIdToken().getJwtToken()}
                                axios
                                    .post(
                                        `${process.env.REACT_APP_API_URL}/f/template`,
                                        body_to_send,{headers}
                                    )
                                    .then(async (data) => {
                                       
                                        await this.guardarCategoria(body_to_send.Category);
                                        this.setState({
                                            is_notification: true,
                                            notification_message: (
                                                <div
                                                    className="alert alert-success"
                                                    role="alert"
                                                >
                                                    {data.data.message}
                                                </div>
                                            ),
                                        });
                                        this.setState({
                                            template_values: {
                                                Id: "",
                                                Name: "",
                                                Description: "",
                                                Category: "",
                                                HasFile: false,
                                                FileField: "",
                                                FileType: "document",
                                            },
                                        });
                                        this.setState({
                                            id_validation: false,
                                            name_validation: false,
                                            description_validation: false,
                                            category_validation: false,
                                            file_field_validation: false,
                                            file_type_validation: false,
                                        });
                                        this.loadTemplates();
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            is_notification: true,
                                            notification_message: (
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    {error.response?error.response.data.message:"No pudo comunicarse al servidor"}
                                                </div>
                                            ),
                                        });
                                    });
                                })
                        }
                        this.loadTemplates();
                    }}
                >
                    {this.props.id !== "" ? "Actualizar" : "Crear"}
                </button>

                {this.state.loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div className="row mt-5">
                    {this.state.loading
                        ? ""
                        : !this.state.template_id && this.renderAllList()}
                </div>
            </div>
        );
    }
}

export default Template;
