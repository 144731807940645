import React, { Component } from "react"
import { Auth } from 'aws-amplify'
import { Redirect } from "react-router-dom"

class AuthStructure extends Component {

    state = {
        isLoggedIn: false,
        sesionVerificada: false
    }

    componentDidMount() {
        Auth.currentSession().then(data => {
            this.setState({
                isLoggedIn: true,
                sesionVerificada: true
            })
        }).catch(error => {
            this.setState({
                isLoggedIn: false,
                sesionVerificada: true
            })
        })
    }

    render() {
        if (!this.state.sesionVerificada) {
            return <h4>Verificando su su sesion</h4>
        } else {
            if (this.state.isLoggedIn) return <Redirect to='/' push />
            return (
                <div className='container'>
                    {this.props.children}
                </div>
            )
        }
    }
}

export default AuthStructure