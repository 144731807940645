
import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react"
import axios from "axios"
import { Auth } from "aws-amplify";

class usuarioseditar extends Component {
    constructor(props) {
        super(props);
        this.state = {
           username:'',
           email:'',
           nombreCompleto:"",
           grupos_info:[],
           valueSelect:"admin",
           valuePassword:"",
           errorMensaje:"",
           is_notifiaction:false,
           rolUsuario:this.props.rolUsuario
        };
        this.enviandoFormulario = this.enviandoFormulario.bind(this);
    }
    componentDidMount() {
        this.lisgarGrupos();
        const query = window.location.search;
        const params = new URLSearchParams(query)
        const role = params.get('role');
        let url = window.location.pathname.split('/');
        this.setState({
            valueSelect: role
        });
        this.listarUsuario(url[3]);

    }
    listarUsuario(user)
    {
        Auth.currentSession().then(session=>{
            
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": "Bearere"+' '+ token}
            
            axios.get(`${process.env.REACT_APP_API_URL}/f/user?usuario=${user}`,{headers})
            .then((data)=>{
                    let usuario="";
                    let email = "";
                    let nombre= "";
                    data.data.map(respuesta=>{
                        if(respuesta.Name==='User')
                        {
                            usuario = respuesta.Value
                        }
                        if(respuesta.Name==='name')
                        {
                            nombre = respuesta.Value
                        }
                        if(respuesta.Name==='email')
                        {
                            email = respuesta.Value
                        }
                      
                    })
                     this.setState({
                        username: usuario,
                        nombreCompleto:nombre,
                        email:email
                     })                
            })  
        })
      
    }
    lisgarGrupos()
    {
        Auth.currentSession().then(session=>{
            
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": "Bearere"+' '+ token}
        axios.get(`${process.env.REACT_APP_API_URL}/f/users/groups`,{headers})
        .then((data)=>{
        
               this.setState(
                   {
                    grupos_info:data.data,
                 
                   }
               )  
            })  
        })
    }
    
    eliminarGrupo()
    {
        return new Promise((resolve,reject)=>{

            Auth.currentSession().then(session=>{
                var token = session.getIdToken().getJwtToken()
                var headers = {"Authorization": "Bearer"+' '+ token}
                let data ={
                    name: this.state.nombreCompleto,
                    username:this.state.username,
                    email:this.state.email,
                    grupo:this.state.valueSelect,
                }

                axios.post(`${process.env.REACT_APP_API_URL}/f/users/delete/grupos`,data,{headers}).then(resp=>{
                    resolve(resp.status)
                }).catch(error=>{
                     
                    this.setState({
                        errorMensaje: error.response.data.message,
                        is_notifiaction:true
                    })
                })
            })
        })
       
    }
    renderListarUsuarios()
    {
   
            return(
            
            <div className="form-group">
              
            <label htmlFor="selectGrupo">Seleccione una opcion</label>
             
            <select className="form-control" value={this.state.valueSelect} onChange={ async(event) =>{
        
                  this.setState({
                    valueSelect:event.target.value,
                })
          
                }}  id="selectGrupo">
              {this.state.grupos_info.map((data,index)=>
              <option key={index} value={data.Nombre}>{data.Nombre}</option>
              )}
            </select>
          </div>
         );
    }
    actualizarUsuario()
    {
        return new Promise((resolve,reject)=>{

            Auth.currentSession().then(session=>{
                var token = session.getIdToken().getJwtToken()
                var headers = {"Authorization": "Bearer"+' '+ token}
                let data ={
                    name: this.state.nombreCompleto,
                    username:this.state.username,
                    email:this.state.email,
                    grupo:this.state.valueSelect,
                }

                axios.post(`${process.env.REACT_APP_API_URL}/f/update/users/`,data,{headers}).then(resp=>{
                    resolve(resp.status)
                }).catch(error=>{
                     
                    this.setState({
                        errorMensaje: error.response.data.message,
                        is_notifiaction:true
                    })
                })
            })
        })
    }
    actualizarPasswordUsuario()
    {
        return new Promise((resolve,reject)=>{

            Auth.currentSession().then(session=>{
                var token = session.getIdToken().getJwtToken()
                var headers = {"Authorization": "Bearer"+' '+ token}
                let data ={
                    name: this.state.nombreCompleto,
                    username:this.state.username,
                    email:this.state.email,
                    grupo:this.state.valueSelect,
                    password: this.state.valuePassword,    
                }

                axios.post(`${process.env.REACT_APP_API_URL}/f/users/password/`,data,{headers}).then(resp=>{
                    resolve(resp.status)
                }).catch(error=>{
                     
                    this.setState({
                        errorMensaje: error.response.data.message,
                        is_notifiaction:true
                    })
                })
            })
        })
    }
    actualizarGrupoUsuario()
    {
        return new Promise((resolve,reject) =>{

            Auth.currentSession().then(session=>{
                var token = session.getIdToken().getJwtToken()
                var headers = {"Authorization": "Bearer"+' '+ token}
                let data ={
                    name: this.state.nombreCompleto,
                    username:this.state.username,
                    email:this.state.email,
                    grupo:this.state.valueSelect,
                }
                axios.post(`${process.env.REACT_APP_API_URL}/f/users/grupos`,data,{headers}).then(resp=>{
                    
                    resolve(resp.status)
                }).catch(error=>{
                     
                    this.setState({
                        errorMensaje: error.response.data.message,
                        is_notifiaction:true
                    })
                })
            })
        })
    }
    enviandoFormulario = async (e) =>
    {
        e.preventDefault();
        let passwordnuevo= this.state.valuePassword.length
        const respuestaActualizarUser = await this.actualizarUsuario();
        if(respuestaActualizarUser===200)
        {
            await this.eliminarGrupo();
            const respuestaGrupoActualizar = await this.actualizarGrupoUsuario();
            
            if(respuestaGrupoActualizar===200)
            {
                if(passwordnuevo!==0)
                {
                    const ActualizarPassword = await this.actualizarPasswordUsuario();
                    if(ActualizarPassword===200)
                    {
                        this.setState({
                        redirect:true,      
                        })
                    }
                }else{
                    this.setState({
                        redirect:true,      
                        })
                }
            }
            
        }
    }
   
    render() {
        if (this.state.redirect) return <Redirect to="/Usuarios" push />;
    try{
        
        return(
          <div>

                
            {this.state.is_notifiaction? (
                <div className="alert alert-danger" role="alert">
                    {this.state.errorMensaje}
            </div>
            ): null}
            <form onSubmit={this.enviandoFormulario}>

            <div className="form-group">
                <label htmlFor="nombreUsuario">Usuario</label>
                <input type="text" value={this.state.username} onChange={(e)=>{

                    this.setState({
                        username: e.target.value
                    })
                }}  className="form-control"  disabled/>
            </div> 

            <div className="form-grupo">
                <label htmlFor="nombreCompleto">Nombre Completo</label>
                <input type="text"  value={this.state.nombreCompleto}  onChange={(e)=>{

                    this.setState({
                        nombreCompleto: e.target.value
                    })
                }} className="form-control"/>
            </div>

            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Correo Electronico</label>
                <input type="email"  value={this.state.email} className="form-control" onChange={(e)=>{

                    this.setState({
                        email: e.target.value
                    })
                }} placeholder="correo" disabled/>
            </div>

            <div className="form-group">
            {this.renderListarUsuarios()}
            </div>
            
          
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" value={this.state.valuePassword} className="form-control" id="exampleInputPassword1"
                 onChange={(e)=>{
                    this.setState({
                        valuePassword: e.target.value
                    })
                 }}
                placeholder="Password"/>
            </div>


            <button type="submit" className="btn btn-primary">Guardar</button>
            </form>
          </div>
        );
    }catch(e) { 
        return e
    }
   
      
    }

}

export default usuarioseditar