import axios from "axios"
import React, { Component } from "react"
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
const s3_object = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
});
class usuarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios_info: [],
            grupos_info:[],
            value:'admin',
            paginasTotales:0,
            paginaActual:0,
            loading:true,
            contadorPaginas:1,
            all_permissions: props.all_permissions,
            permissions: props.permissions,
            usuario:'',
        }
      
    }
    componentDidMount() {

        this.listarUsuarios('admin',1);
        this.lisgarGrupos();

      
    }
    
    listarUsuarios(grupo,pagina)
    {
        Auth.currentSession().then(session=>{
            
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": 'Bearer'+' '+ token}
            axios.get(`${process.env.REACT_APP_API_URL}/f/users/?pagina=${pagina}&group=${grupo}&limite=10`,{headers})
            .then((data)=>{
                   this.setState(
                       {
                           usuarios_info: data.data.data,
                           paginaActual: data.data.paginaActual,
                           paginasTotales: data.data.paginaTotal,
                           loading:false
                          
                       }
                   )
   
                
            })
        })
      
    }
    lisgarGrupos()
    {
        Auth.currentSession().then(session=>{
   
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": 'Bearer'+' '+ token}

        axios.get(`${process.env.REACT_APP_API_URL}/f/users/groups`,{headers})
        .then((data)=>{
        
               this.setState(
                   {
                    grupos_info:data.data,
                 
                   }
               )
            
        })  
        });
  
    }


    renderListarUsuarios()
    {
   
            return(
            <div className="form-group">
              
            <label htmlFor="exampleFormControlSelect1">Seleccione un Rol   {this.state.all_permissions}</label>

             
            <select className="form-control" onChange={ async(event) =>{
        
                  this.setState({
                    value:event.target.value,
                    loading:true,
                    contadorPaginas:1,
                    paginasTotales:0,
                    paginaActual:0
                })
          
                 this.listarUsuarios(event.target.value,1)
              

                }}  id="exampleFormControlSelect1">
              {this.state.grupos_info.map((data,index)=>
              <option key={index} value={data.Nombre}>{data.Nombre}</option>
              )}
            </select>
          </div>
         );
    }
    paginacion()
    {
      
       if(!this.state.loading)
       {
        return(
            <div className="w-100" >
            
                {this.state.paginasTotales <= 1? (
                    ""
                ) : (
                    <div className="d-flex justify-content-between">
                    
                    {this.state.contadorPaginas!==1? (
                         <button className="btn btn-secondary" onClick={
                            async()=>
                            {
                                this.setState({
                                    contadorPaginas: this.state.contadorPaginas-=1,
                                    loading:true
                                })
                                this.listarUsuarios(this.state.value,this.state.contadorPaginas)
                            }
                        }>Anterior</button>
                    ):
                    ("")}
                   
                    {this.state.contadorPaginas===this.state.paginasTotales ? (
                        ""
                    ): (
                        <button className="btn btn-primary" onClick={ async() =>
                            {
                                this.setState({
                                    contadorPaginas: this.state.contadorPaginas+=1,
                                    loading:true
                                })
                                this.listarUsuarios(this.state.value,this.state.contadorPaginas)
                            }   
                           
                        }>Siguiente</button>
                    )}
                    </div>
                )}
           
            </div>
           )
       }else{
        return ""
       }
      
    }
    eliminarUsuario (usuario) 
    {
      
       Auth.currentSession().then(session=>{
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": 'Bearer'+' '+ token}
        axios.post(`${process.env.REACT_APP_API_URL}/f/usuario/delete`,{
            'usuario': usuario,
        },{headers})
        .then(async (data)=>{
            let idCarpetaUser = data.data.respuesta.split('-');
            this.setState(
                {
             
                    loading:true,
            
                }
            )
            const listParams = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Prefix: 'files/'+idCarpetaUser[0]
                };
                 const listedObjects =  await s3_object.listObjectsV2(listParams).promise();
                  if (listedObjects.Contents.length === 0) return;
                        const deleteParams = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                            Delete: { Objects: [] }
                        };
             
                    listedObjects.Contents.forEach(({ Key }) => {
                    deleteParams.Delete.Objects.push({ Key });
                    });
                    await s3_object.deleteObjects(deleteParams).promise();
                    this.listarUsuarios(this.state.value,this.state.contadorPaginas);
        })  
    
        });
   
    }
    render() {
    try{
        
        return(
            
          
            <div className="list-users">
                
                {this.state.permissions["USUARIOS"] || this.state.all_permissions?
                <div>
                       {this.renderListarUsuarios()}
                       {this.state.loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : 
                
                (
                    <div>
                          <Link to={`/usuario/create/`}    className="btn btn-primary mb-2">Crear Usuario</Link>
                          <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Usuario</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Rol</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.usuarios_info.map((data, index) => (  
                      <tr key={index}>  
                        <td>{data.username}</td>  
                        <td>{data.email}</td>  
                        <td>{data.rol}</td>  
                        
                        <td>
                            <Link to={`/usuario/editar/${data.username}?role=${data.rol}`}    className="btn btn-primary">Editar</Link>
                            <button className="btn btn-danger" data-toggle="modal" data-target="#exampleModal"onClick={async ()=>{
                                this.setState({
                                    usuario:data.username
                                })
                        }}>Eliminar</button>
                        </td>
                     
                      </tr>  
                     ))}  
                    </tbody>
                  </table>

                  <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                      
                        <div className="modal-body">
                            Deseas Eliminar a {this.state.usuario} ?
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={async ()=>{
                                await this.eliminarUsuario(this.state.usuario)
                                
                        }}>Si</button>
                        </div>
                    </div>
                    </div>
                    </div>

                    </div>
                )}


                {this.paginacion()}
                </div>
                
                : <div className="alert alert-danger mb-5 mt-5 text-center" role="alert">
                        Usuario no Autorizado
                  </div>
                  
                }
        
          </div>
        );
    }catch(e) { 
        return e
    }
   
      
    }

}

export default usuarios