import axios from "axios";
import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import { Auth } from "aws-amplify";

class LogsMenajesProgramados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      telefonos: [],
      logsdata: [],
      dataMensajeProgramado:[],
      detalleMensajeProgramado:[],
      templated_select: "",
      templated_name: "",
      telefono_select: "",
      telefono_name: "",
      fecha_inicio: null,
      fecha_mostrar: null,
      fecha_fin: null,
      fecha_fin_mostrar: null,
      tituloTemplatemodal: "",
      titulofechamodal: "",
      tituloHoramodal: "",
      estadoModal: "",
      dataEliminarid:"",
      loading: false,
      cargadoTemplate: false,
      mensajeError: false,
      mensajeResultados: false,
      estadoDetalleModal:false,
      estadoModalEliminar:false,
      nextToken:"",
      prevToken:"",
      contadorPage:0,
      paginacionTokens:[null]
    };
  }
  componentDidMount() {
    Auth.currentSession().then((session) => {
      var headers = {
        Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}/f/template?limit=200`, {
          headers,
        })
        .then((data) => {
          this.setState({
            templates: data.data.templates,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${process.env.REACT_APP_API_URL}/f/telefono?limit=200`, {
          headers,
        })
        .then((data) => {
          this.setState({
            telefonos: data.data.telefonos,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({
            dropdown_telefono_busy: false,
            getting_data: false,
          });
        });
    });
  }
  eliminarMensajeProgramado() {
    Auth.currentSession().then((session) => {
      var headers = {
        Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/f/delete/mensaje_programado`,
          { evento: this.state.dataEliminarid },
          { headers }
        )

        .then((data) => {
          this.setState({
            loadingCargando: true,
            logsdataLoading: true,
          });
          this.dataTableRender("buscar","");
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  detallesMensajesLogs(id) {
    Auth.currentSession().then((session) => {
      var headers = {
        Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/f/mensajes/historial/detalle`,
          { idevento: id },
          { headers }
        )

        .then((data) => {
          this.setState({
            detalleMensajeProgramado: data.data.respuesta,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  rendermodalEliminarDetalle() {
    return (
      <>
        <div
          className="modal fade"
          id="exampleModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  ¿Deseas cancelar el envio de {this.state.tituloTemplatemodal}{" "}
                  - {this.state.titulofechamodal} {this.state.tituloHoramodal} ?
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={async (event) =>
                    this.eliminarMensajeProgramado(this.state.idclowdwatch)
                  }
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  dataTableRender(tipo,token) {
    this.setState({
      cargadoTemplate: true,
      loading: false,
      mensajeError: false,
    });
    let fechainicio = this.state.fecha_inicio;
    let fechafin = this.state.fecha_fin;
    if (this.state.fecha_inicio === null) {
      fechainicio = "";
    }
    if(this.state.fecha_fin === null)
    {
      fechafin= "";
    }
    Auth.currentSession().then((session) => {
      var headers = {
        Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
      };
      
   
      if(tipo!=="paginacion")
      {
        this.setState({
          contadorPage:0,
          prevToken:"",
          nextToken:"",
          paginacionTokens:[null]
        })
        token=""
      }
       
      

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/f/mensajes/historial`,
          {
            fecha: fechainicio,
            fecha_fin:fechafin,
            numero: this.state.telefono_select,
            template_id: this.state.templated_select,
            token:token
          },
          { headers }
        )
 
        .then((data) => {
         
       
          if(this.state.paginacionTokens.indexOf(data.data.is_next)<=0 )
          {
            if(data.data.is_next != null)
            {
              this.state.paginacionTokens.push(data.data.is_next)
            }
           
          }
        

          this.setState({
            nextToken: data.data.is_next,
          })
       
          if (data.status === 200) {
            var headers = {
              Authorization:
                "Bearer" + " " + session.getIdToken().getJwtToken(),
            };
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/f/listar/logs`,
                {
                  data: data.data,
                },
                { headers }
              )
              .then((respuesta) => {
                if (respuesta.data.respuesta.length > 0) {
                  this.setState({
                    logsdata: respuesta.data.respuesta,
                    mensajeError: false,
                    cargadoTemplate: true,
                    loading: true,
                    mensajeResultados: false,
                  });
                } else {
                  this.setState({
                    mensajeResultados: true,
                    mensajeError: false,
                    cargadoTemplate: false,
                    loading: true,
                  });
                }
              });
          }
        })
        .catch((error) => {
          this.setState({
            loading: true,
            cargadoTemplate: false,
            mensajeError: true,
          });
        });
    });
  }
  rendercargando() {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  renderMensaje(tipoclase, mensaje) {
    return (
      <div className={`alert alert-${tipoclase}`} role="alert">
        <p>{mensaje}</p>
      </div>
    );
  }
  rendermostrarModaldetalle() {
    return (
      <>
        <div
          class="modal fade"
          id="modalDetalle"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalDetalle"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card">
                  <div class="card-body">
                    <p className="font-weight-bold">
                      Template: {this.state.tituloTemplatemodal}
                    </p>
                    <p className="font-weight-bold">
                      Fecha de Envío: {this.state.titulofechamodal}{" "}
                  
                      {this.state.tituloHoramodal}
                    </p>
                    <p className="font-weight-bold">
                    {this.state.estadoModal === "queue" ? (
                        <>En cola</>
                      ) : this.state.estadoModal === "success" ? (
                        <>Completado</>
                      ) : this.state.estadoModal === "cancel" ? (
                        <>Cancelado</>
                      ) : (
                        <>Error</>
                      )}
                    
                    </p>
                  </div>
                </div>

                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Respuesta
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">{this.renderDetalleTabla()}</div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Data
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ReactJson src={this.state.dataMensajeProgramado} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  renderDetalleTabla() {
    return (
      <div>
        {this.state.detalleMensajeProgramado.map((data, index) => (
          <div className="card mt-2 mb-2" key={index}>
            <div class="card-header">Acciones Mensaje:{index + 1}</div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Hora de creacion:{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </li>
              <li className="list-group-item">
                Fecha programada: {data.fecha} {data.hora}
              </li>
              <li className="list-group-item">
              Status:
              {data.status === "queue" ? (
                        <>En cola</>
                      ) : this.state.estadoModal === "success" ? (
                        <>Completado</>
                      ) : this.state.estadoModal === "cancel" ? (
                        <>Cancelado</>
                      ) : (
                        <>Error</>
                      )}
               
              </li>
            </ul>
          </div>
        ))}
      </div>
    );
  }

  renderTableContent() {
    return (
      <>
        {this.state.loading ? (
          <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Template ID</th>
                  <th>Estado</th>
                  <th>Fecha de Envío</th>
                  <th>Enviado desde</th>
                  <th>Fecha de creación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.logsdata.map((data, index) => (
                  <tr key={index}>
                    <td>{data.template_id}</td>
                    <td>
                      {data.status === "queue" ? (
                        <>En cola</>
                      ) : data.status === "success" ? (
                        <>Completado</>
                      ) : data.status === "cancel" ? (
                        <>Cancelado</>
                      ) : (
                        <>Error</>
                      )}
                    </td>
                    <td>
                      {data.fecha} {data.hora}
                    </td>
                    <td>{data.number}</td>
                    <td>
                      {data.created_at}
                    </td>

                    <td>
                      <button
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#modalDetalle"
                        onClick={async (event) => {
                          this.setState({
                            dataMensajeProgramado: data.data,
                            estadoDetalleModal: true,
                            tituloTemplatemodal: data.template_id,
                            tituloHoramodal: data.hora,
                            titulofechamodal: data.fecha,
                            estadoModal: data.status,
                          });
                          this.detallesMensajesLogs(data.id);
                        }}
                      >
                        Detalle
                      </button>
                      {data.status === "queue" ? (
                        <button
                          data-toggle="modal"
                          data-target="#exampleModal"
                          className="btn btn-danger mx-2"
                          onClick={async (event) => {
                            this.setState({
                              estadoModalEliminar: true,
                              dataEliminarid: data.id,
                              tituloTemplatemodal: data.template_id,
                              tituloHoramodal: data.hora,
                              titulofechamodal: data.fecha,
                            });
                          }}
                        >
                          Cancelar
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="row justify-content-between">
                    <button className={this.state.contadorPage!==0? 'btn cemaco-button': 'btn cemaco-button disabled'} onClick={async(event)=>{
                                  
                                  if(this.state.contadorPage!==0)
                                  {
                                    this.setState(
                                      {
                                        contadorPage: this.state.contadorPage-=1,
                                      }
                                    ) 
                                   
                                    let token=this.state.paginacionTokens[this.state.contadorPage]
                                  
                                    this.dataTableRender("paginacion",token)
                                  }   
                    }}>Anterior</button>
                    <button className={this.state.contadorPage>=0 && this.state.nextToken!==null? 'btn cemaco-button' : 'btn cemaco-button disabled '} onClick={ async(event)=>{
                      if(this.state.contadorPage>=0 && this.state.nextToken!==null)
                      {
                        this.setState(
                        {
                          contadorPage: this.state.contadorPage+=1,
                         
                         }) 
                        
                        this.dataTableRender("paginacion",this.state.nextToken)
                      }
                     
                    }
                    }>Siguiente</button>
            </div>
          </>
        ) : (
          <>{this.rendercargando()}</>
        )}
      </>
    );
  }
 
  comboboxSelected() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-4">
            <DropdownList
              placeholder="Seleccione un template"
              dataKey="Id"
              textField="Name"
              value={this.state.templated_name}
              data={this.state.templates}
              onChange={async (selected_template) => {
                await this.setState({
                  templated_select: selected_template.Id,
                  templated_name: selected_template.Name,
                });
              }}
            />
          </div>

          <div className="col-md-4">
            <DropdownList
              placeholder="Seleccione un número de telefono (numero para enviar el mensaje)"
              dataKey="Phone"
              textField="Name"
              value={this.state.telefono_name}
              data={this.state.telefonos}
              onChange={async (selected) => {
                await this.setState({
                  telefono_select: selected.Phone,
                  telefono_name: selected.Name,
                });
              }}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              placeholder="Fecha de inicio"
              valueFormat={{ day:"numeric", month: "numeric", year: "numeric" }}
              value={this.state.fecha_mostrar}
              onChange={async (date) => {
                let moment_date = moment(date).format("DD-MM-YYYY");
                this.setState({
                  fecha_inicio: moment_date,
                  fecha_mostrar: date,
                });
              }}
            />
          </div>

          <div className="col-md-2">
            <DatePicker
              placeholder="Fecha fin"
              valueFormat={{ day:"numeric", month: "numeric", year: "numeric" }}
              value={this.state.fecha_fin_mostrar}
              onChange={async (date) => {
                let moment_date = moment(date).format("DD-MM-YYYY");
                this.setState({
                  fecha_fin: moment_date,
                  fecha_fin_mostrar: date,
                });
              }}
            />
          </div>

        </div>
      </>
    );
  }
  limpiarSelects() {
    this.setState({
      templated_select: "",
      templated_name: "",
      telefono_select: "",
      telefono_name: "",
      fecha_inicio: null,
      fecha_mostrar: null,
      fecha_fin: null,
      fecha_fin_mostrar: null,
      loading: false,
      cargadoTemplate: false,
      mensajeError: false,
    });
  }
  render() {
    return (
      <>
        <div className="container">
          <div className="row mt-5 mb-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="text-center">Logs Mensajes Programados</h1>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  {this.renderMensaje(
                    "info",
                    " Se pueden seleccionar varios filtros de forma simultanea, pero todos son opcionales."
                  )}
                </div>
              </div>
              {this.comboboxSelected()}
              <div className="d-flex justify-content-end mt-5">
                <div
                  className="btn cemaco-button"
                  onClick={async () => {
                    this.dataTableRender("buscar","");
                  }}
                >
                  Buscar
                </div>
                <div
                  className="btn cemaco-button mx-2"
                  onClick={async () => {
                    this.limpiarSelects();
                  }}
                >
                  limpiar
                </div>
              </div>
            </div>
          </div>

          {this.state.cargadoTemplate ? this.renderTableContent() : <></>}
          {this.state.mensajeError ? (
            this.renderMensaje(
              "danger text-center font-weight-bold",
              "Hubo un error intentalo nuevamente mas tarde"
            )
          ) : (
            <></>
          )}
          {this.state.mensajeResultados ? (
            this.renderMensaje(
              "danger text-center font-weight-bold",
              "No se encontraron resultados"
            )
          ) : (
            <></>
          )}

          {this.state.estadoDetalleModal ? this.rendermostrarModaldetalle() : <></>}

          {this.state.estadoModalEliminar ? this.rendermodalEliminarDetalle() : <></>}
        </div>
      </>
    );
  }
}

export default LogsMenajesProgramados;
