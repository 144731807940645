import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";
import axios from "axios";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { FilePond } from "react-filepond";

const s3_object = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
});
class Individual extends Component {
  state = {
    templates: [],
    telefonos: [],

    dropdown_busy: true,
    dropdown_disabled: false,
    dropdown_telefono_busy: true,
    dropdown_telefono_disabled: false,

    selected_template: {
      Id: "",
      Name: "",
      Description: "",
      FileField: "",
      FileType: "",
    },
    selected_telefono: {
      Phone: "",
      Name: "",
    },

    is_template_selected: false,
    is_telefono_selected: false,

    template_info_loaded: false,
    telefono_info_loaded: false,

    template_notification: <></>,
    template_load: <></>,
    template_fields: [],
    puede_enviar_mensaje: false,
    rolUsuario: "",
    mensajeCargarArchivo: "True",
    loader: (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ),
    is_loading: false,
    selected_fields: new Set(),

    page_notification: "",
    show_page_notification: false,

    sending_request: false,
    loggedUser: "",
    archivos_s3: [],
    usuarioAwss3: "",
    archivoSeleccionado: "",
    carpetaArchivos: "files",
    urlGenerada: "",
    categoriaSelect:[],
    estructuraTemplate: ""
  };

  constructor(props) {
    super(props);
    this.loadS3Files = this.loadS3Files.bind(this);
    this.loadS3FilesUsuario = this.loadS3FilesUsuario.bind(this);
    this.renderTemplateFieldsForm = this.renderTemplateFieldsForm.bind(this);
    this.renderTemplates = this.renderTemplates.bind(this);
    this.renderTelefonos = this.renderTelefonos.bind(this);
    this.fieldsHasError = this.fieldsHasError.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.seleccionarOpcionarchivos = this.seleccionarOpcionarchivos.bind(this);
    this.apicategoriaslistar=this.apicategoriaslistar(this);
    this.renderSearchTemplatesBusiness = this.renderSearchTemplatesBusiness.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let idCarpetaUser = user.attributes.sub.split("-");
        this.setState({
          loggedUser: user.username,
          usuarioAwss3: idCarpetaUser[0],
        });
      })
      .then(async () => {
        let arregloArchivos = [];
        let respuestaArchivos = await this.loadS3Files();
        respuestaArchivos.map((x) => arregloArchivos.push(x));

        let respuestaArchivosUsuarios = await this.loadS3FilesUsuario();
        respuestaArchivosUsuarios.map((x) => arregloArchivos.push(x));

        this.setState({
          archivos_s3: arregloArchivos,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    Auth.currentSession().then((session) => {
      var token = session.getIdToken().getJwtToken();
      var headers = { Authorization: "Bearer" + " " + token };
      axios
        .get(`${process.env.REACT_APP_API_URL}/f/template?limit=200`, {
          headers,
        })
        .then((data) => {
         
          this.setState({
            templates: data.data.templates,
            dropdown_busy: false,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({
            dropdown_busy: false,
          });
        });
      axios
        .get(`${process.env.REACT_APP_API_URL}/f/telefono?limit=200`, {
          headers,
        })
        .then((data) => {
          this.setState({
            telefonos: data.data.telefonos,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({
            dropdown_telefono_busy: false,
            getting_data: false,
          });
        });
    });
  }
  apicategoriaslistar()
  {
      Auth.currentSession().then((session) => {
          var headers = {
            Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
          };
          let data ={
              categoria: ""
          }
          axios
            .post(`${process.env.REACT_APP_API_URL}/f/categorias`,
            data,{headers},
            )
            .then((data) => {
              this.setState({
                  categorias: data.data.respuesta
              })        
            })
            .catch((error) => {
              console.log(error);
            })
        });
  }
  apiSubcategorias(categoria)
  {
      Auth.currentSession().then((session) => {
          var headers = {
            Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
          };
          let data ={
              categoria: categoria
          }
          axios
            .post(`${process.env.REACT_APP_API_URL}/f/categorias`,
            data,{headers},
            )
            .then((data) => {
                  this.setState({
                      subcategorias: data.data.respuesta
                  }) 
            })
            .catch((error) => {
              console.log(error);
            })
        });
  }
  
  listarSubCategorias(id)
  {
      this.apiSubcategorias(id.categoria)
      this.setState({
          categoriaSelect: id.categoria
      })
  }
  renderCategorias()
  {
      return ( <>
         <label className="font-weight-bold">Seleccione Categoría Template</label>
          <DropdownList
          data={this.state.categorias}
          dataKey="id"
          textField="categoria"
          placeholder="Seleccione una categoria"
          renderListItem={ ({ item }) => (
              <span className="mt-1 mb-1">
              <strong>{item.categoria}</strong>
              </span>
            )}
          onSelect={async (id) => {
              this.listarSubCategorias(id);
          }} />
          
          </>
      );
  }
  renderSubCategorias()
  {
      return ( <>
      <label className="font-weight-bold">Seleccione Template</label>
          <DropdownList
          data={this.state.subcategorias}
          dataKey="Id"
          textField="Name"
          placeholder="Seleccione una categoria principal"
          renderListItem={ ({ item }) => (
              <span className="mt-1 mb-1">
              <strong>{item.Name}</strong>({item.Id})
              </span>
            )}
          onSelect={async (selected_template) => {        

            await this.setState({
              selected_template: selected_template,
              dropdown_disabled: true,
              is_template_selected: true,
              template_info_loaded: false,
              is_loading: true,
              FileType: selected_template.FileType,
            });
            this.renderTemplateFieldsForm();
            this.renderSearchTemplatesBusiness();
          }} />
          
          </>
      );
  }
  renderCrearModal()
  {
    return (
      <>
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                ...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  loadS3FilesUsuario() {
    return new Promise((resolve) => {
      let loaded_files = s3_object
        .listObjectsV2({
          Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
          Prefix: `files/general`,
        })
        .promise();
      loaded_files
        .then((Contents, IsTruncated, NextContinuationToken) => {
          let items = [];
          Contents.Contents.forEach((item, index) => {
            items.push({
              category: "public",
              key: item.Key,
              url: `https://suplemento.soportecm.com/${item.Key}`,
              nombre: item.Key.replace(`files/general`, ""),
            });
          });
          resolve(items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    });
  }

  loadS3Files() {
    return new Promise((resolve) => {
      let loaded_files = s3_object
        .listObjectsV2({
          Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
          Prefix: `files/${this.state.usuarioAwss3}`,
        })
        .promise();
      loaded_files
        .then((Contents, IsTruncated, NextContinuationToken) => {
          let items = [];
          Contents.Contents.forEach((item, index) => {
            let nombreArchivo = item.Key.replace(
              "files/" + this.state.usuarioAwss3 + "/",
              ""
            );
            items.push({
              category: "personales",
              key: item.Key,
              url: `https://suplemento.soportecm.com/${item.Key}`,
              nombre: nombreArchivo,
            });
          });
          resolve(items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    });
  }
  renderTemplateArchivo = ({ item }) => {
    return (
      <>
        <span className="mt-1 mb-1">
          <strong>{item.nombre}</strong>({item.url})
        </span>
      </>
    );
  };
  renderTemplates = ({ item }) => {
    return (
      <>
        {item.hasOwnProperty("type") ? (
          <Link
            className="btn btn-lg btn-block cemaco-button-green mt-1 mb-1"
            to="/template"
            push="true"
          >
            {item.Name}
          </Link>
        ) : (
          <span className="mt-1 mb-1">
            <strong>{item.Name}</strong> ({item.Id})
          </span>
        )}
      </>
    );
  };

  renderTelefonos = ({ item }) => {
    return (
      <>
        <span className="mt-1 mb-1">
          <strong>{item.Name}</strong> ({item.Phone})
        </span>
      </>
    );
  };

  fieldsHasError() {
    let hasError = false;
    this.state.template_fields.forEach((item, index) => {
      if (item.value === "") hasError = true;
    });
    return hasError;
  }
  renderSearchTemplatesBusiness()
  {
    const headers={
      Authorization:  `Bearer ${process.env.REACT_APP_TOKEN_GRAP_FACEBOOKBU}`,
    }
    axios
    .get(`https://graph.facebook.com/v17.0/${process.env.REACT_APP_USER_GRAP_FACEBOOK}/message_templates?status=APPROVED&name=${this.state.selected_template.Id}`,{ headers })
    .then(async (respuseta) => {
        
          const {data} = respuseta.data;
          data[0].components.forEach(e => {
                 if(e.type==="BODY")
                 {
                
                  this.setState({
                    estructuraTemplate: e.text
                  })
                 }
          });
         
    });
  }
  renderTemplateFieldsForm() {
    if (!this.state.is_template_selected) return <></>;

    Auth.currentSession().then((session) => {
      var token = session.getIdToken().getJwtToken();
      var headers = { Authorization: "Bearer" + " " + token };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/f/template/fields/${this.state.selected_template.Id}`,
          { headers }
        )
        .then(async (data) => {

           
          if (data.data.Fields.length === 0) {
            this.setState({
              show_page_notification: true,
              puede_enviar_mensaje: false,
              page_notification: (
                <div className="alert alert-danger" role="alert">
                  El template <strong>{this.state.selected_template.Id}</strong>{" "}
                  no tiene campos, para poder enviar mensajes con esta plantilla
                  debe{" "}
                  <Link
                    to={`/template/${this.state.selected_template.Id}/field`}
                    className="alert-link"
                  >
                    crear campos
                  </Link>
                  .
                </div>
              ),
            });
          } else {
        
            let fields = [];
            fields.push({
              Id: "Phone",
              Name: "Phone",
              Position: -1,
              hasError: false,
              errorText: "",
              value: "",
            });

            data.data.Fields.forEach((item) => {
              fields.push({
                Id: item.Id,
                Name: item.Name,
                Position: -1,
                hasError: false,
                errorText: "",
                value: "",
              });
            });

            this.setState({
              template_fields: fields,
              puede_enviar_mensaje: true,
              show_page_notification: false,
              page_notification: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            template_notification: <h1>Error</h1>,
          });
        })
        .finally(() => {
          this.setState({
            template_info_loaded: true,
            dropdown_disabled: false,
            is_loading: false,
          });
        });
    });
  }

  sendMessage() {
    if (!this.fieldsHasError()) {
      this.setState({
        sending_request: true,
        show_page_notification: false,
      });
      let mensaje_enviar = {};
      let fields = this.state.template_fields;
      fields.forEach((item) => {
        mensaje_enviar[`${item.Name}`] = item.value;
      });
      let data_enviar = [];
      data_enviar.push(mensaje_enviar);
      let body = {
        FromPhone: this.state.selected_telefono.Phone,
        Messages: data_enviar,
        User: this.state.loggedUser,
      };
      Auth.currentSession().then((session) => {
        var headers = {
          Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/f/mensajes/${this.state.selected_template.Id}`,
            body,
            { headers }
          )
          .then((data) => {
            fields.forEach((item, index) => {
              fields[index].hasError = false;
              fields[index].errorText = "";
              fields[index].value = "";
            });
            this.setState({
              page_notification: (
                <div className="alert alert-success">{data.data.message}</div>
              ),
              template_fields: fields,
            });
          })
          .catch((error) => {
            this.setState({
              page_notification: (
                <div className="alert alert-success">
                  {error.response.data.message}
                </div>
              ),
            });
          })
          .finally(() => {
            this.setState({
              sending_request: false,
              show_page_notification: true,
            });
          });
      });
    } else {
      this.setState({
        show_page_notification: true,
        page_notification: (
          <div className="alert alert-danger" role="alert">
            Revise el formulario y corrija los errores antes de enviar el
            mensaje.
          </div>
        ),
      });
    }
  }
  archivosSelecionadosEnviar(data) {

    let obtenerUltimoValor =
    this.state.template_fields[[this.state.template_fields.length - 1]];
    obtenerUltimoValor.value = data;
    this.setState({
      template_fields: this.state.template_fields,
    });
  }
  archivosSelecionados() {
    return (
      <>
        <div className="col-md-12">
          <DropdownList
            data={this.state.archivos_s3}
            dataKey="key"
            textField="url"
            groupBy="category"
            renderListItem={this.renderTemplateArchivo}
            renderListGroup={({ group }) => (
              <div className="row">
                <div className="col-md-12">
                  <p className="text-center font-italic">{group}</p>
                  <hr />
                </div>
              </div>
            )}
            onSelect={async (select) => {
              await this.archivosSelecionadosEnviar(select.url);
            }}
          />
        </div>
      </>
    );
  }
  cargarArchivos() {
    return (
      <>
        <div className="mt-5 mb-3">
          <div className="row">
            <div className="col-md-12">
              {this.state.has_notification ? this.state.notification : null}
            </div>
          </div>
          <div></div>

          <div className="row">
            <div className="col-md-12">
              <FilePond
                files={this.state.archivos_cargar}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    s3_object
                      .upload({
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: `${this.state.carpetaArchivos}/${this.state.usuarioAwss3}/${file.name}`,
                        Body: file,
                        ACL: "public-read",
                        ContentType: file.type,
                      })
                      .promise()
                      .then((response) => {
                        load(response.Key);
                        this.archivosSelecionadosEnviar(`https://${response.Bucket}/${response.Key}`);
                        this.loadS3Files();
                      })
                      .catch((error) => {
                        error("Hubo un error cargando el archivo");
                      });
                  },
                }}
                onupdatefiles={(items) => {
                  this.setState({
                    archivos_cargar: items.map((fileItem) => fileItem.file),
                  });
                }}
                onerror={(error) => {
                  this.setState({
                    has_error: true,
                    error: error.main,
                  });
                }}
                allowMultiple={true}
                labelIdle="Arrastra o carga archivos publicos"
              />
            </div>
          </div>
        </div>
        <div className="col-12">
        <div class="alert alert-dark" role="alert">
                                        <h4>Contenido Template</h4>
                                    {this.state.estructuraTemplate}
                                    </div>
        </div>
      </>
    );
  }
  seleccionarOpcionarchivos(event) {
    this.setState({
      mensajeCargarArchivo: event.target.value,
    });
  }
  render() {
    return (
      <div className="row mt-5 mb-3">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">Mensaje individual</h1>
            </div>
          </div>
          {this.state.show_page_notification ? (
            <div className="row mt-5 mb-3">
              <div className="col-md-12">{this.state.page_notification}</div>
            </div>
          ) : null}
          <div className="row mt-3">
              <div className="col-md-6">
                  {this.renderCategorias()}
              </div>
              <div className="col-md-6">
                  {this.renderSubCategorias()}
              </div>
            
          </div>
          
          <div className="row mt-3">
          <div className="offset-10 col-md-3 mb-4">
             
              {this.renderCrearModal()}
              </div>
            <div className="col-md-12">
              <DropdownList
                placeholder="Seleccione un número de telefono (numero para enviar el mensaje)"
                disabled={this.state.dropdown_telefono_disabled}
                autoFocus="False"
                busy={this.state.dropdown_telefono_busy}
                renderListItem={this.renderTelefonos}
                dataKey="Phone"
                textField="Name"
                data={this.state.telefonos}
                groupBy={(template) => template.hasOwnProperty("type")}
                renderListGroup={({ group }) => {
                  return group ? <hr /> : <></>;
                }}
                onSelect={async (selected_telefono) => {
                  this.setState({
                    selected_telefono: selected_telefono,
                    dropdown_telefono_disabled: false,
                    is_telefono_selected: true,
                  });
                }}
              />
            </div>
          </div>
          {this.state.puede_enviar_mensaje ? (
            <>
              {
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="alert alert-info" role="alert">
                      <h4>Información importante</h4>
                      <p>
                        El campo{" "}
                        <strong>
                          {this.state.selected_template.FileField}
                        </strong>{" "}
                        del template debe contener un link que contenga archivo
                        de tipo{" "}
                        <strong>{this.state.selected_template.FileType}</strong>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              }


              <div className="row mt-3">
                {this.state.FileType !== "" ? (
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value="True"
                        checked={this.state.mensajeCargarArchivo === "True"}
                        onChange={this.seleccionarOpcionarchivos}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Crear un archivo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        value="False"
                        checked={this.state.mensajeCargarArchivo === "False"}
                        onChange={this.seleccionarOpcionarchivos}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Seleccionar archivo existente
                      </label>
                    </div>
                    {this.state.mensajeCargarArchivo === "True"
                      ? this.cargarArchivos()
                      : this.archivosSelecionados()
                      
                      }
                  </div>
                ) : (
                  <></>
                )}
              </div>


              <div className="row mt-3">
                <div className="col-md-12">
                  <hr />
                  {this.state.template_fields.map((field, index) => {
                    return (
                      <div className="row" key={field.Id}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor={field.Id}>
                              {field.Name === "Phone"
                                ? "Número de teléfono"
                                : field.Name}
                            </label>
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (field.hasError ? " is-invalid" : "")
                              }
                              id={field.Id}
                              placeholder={
                                field.Name === "Phone"
                                  ? "Número de teléfono"
                                  : field.Name
                              }
                              value={field.value}
                              onChange={(e) => {
                                let fields = this.state.template_fields;
                                fields[index].value = e.target.value;
                                fields[index].hasError = e.target.value === "";
                                fields[index].errorText =
                                  e.target.value === ""
                                    ? "El valor del campo no puede ser vacio"
                                    : "";
                                this.setState({
                                  template_fields: fields,
                                });
                              }}
                              aria-describedby={field.Id + "Feedback"}
                            ></input>
                            <div
                              id={field.Id + "Feedback"}
                              className={
                                "invalid-feedback" +
                                (field.hasError ? "" : " d-none")
                              }
                            >
                              {field.errorText}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="d-flex justify-content-end mt-3">
                    {this.state.sending_request ? (
                      <button className="btn btn-primary" disabled>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Procesando...</span>
                      </button>
                    ) : (
                      <button
                        className={
                          "btn cemaco-button-green" +
                          (this.fieldsHasError() ? " disabled" : "")
                        }
                        onClick={this.sendMessage}
                      >
                        Enviar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : this.state.is_loading ? (
            <div className="row mt-5">
              <div className="col-md-12">{this.state.loader}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Individual;
