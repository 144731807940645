import { FilePond } from "react-filepond";
import React, { Component } from "react";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import axios from "axios";
const s3_object = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
});
class ArchivosPublicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos_cargar: [],
      has_error: false,
      error: "",
      has_notification: false,
      notification: "",
      archivos_s3: [],
      key_to_delete: "",
      username: "",
      isLoggedIn: false,
      sesionVerificada: false,
      permissions: {},
      all_permissions: false,
    };
    this.renderS3Files = this.renderS3Files.bind(this);
    this.loadS3Files = this.loadS3Files.bind(this);
    this.permisosdeUsuario = this.permisosdeUsuario.bind(this);
  }

  componentDidMount() {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          let idCarpetaUser = user.attributes.sub.split("-");
          this.setState({
            username: idCarpetaUser[0],
          });
        })
        .then(() => {
          this.loadS3Files();
          this.permisosdeUsuario();
        });
    } catch (e) {
      this.setState({
        notification: (
          <div className="alert alert-danger">
            Hubo un error obteniendo los archivos cargados
          </div>
        ),
        has_error: true,
      });
    }
  }
  permisosdeUsuario() {
    Auth.currentSession()

      .then((data) => {
        var headers = {
          Authorization: "Bearer" + " " + data.getIdToken().getJwtToken(),
        };

        axios
          .get(`${process.env.REACT_APP_API_URL}/f/permisos`, { headers })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.permissions === "*") {
                this.setState({ all_permissions: true });
              } else this.setState({ permissions: response.data.permissions });
            }
          })
          .catch((ex) => {});
      })
      .catch((error) => {
        this.setState({
          isLoggedIn: false,
          sesionVerificada: true,
        });
      });
  }
  loadS3Files() {
    let loaded_files = s3_object
      .listObjectsV2({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Prefix: `files/general`,
      })
      .promise();
    loaded_files
      .then((Contents, IsTruncated, NextContinuationToken) => {
        let items = [];
        Contents.Contents.forEach((item, index) => {
          items.push({
            key: item.Key,
            url: `https://suplemento.soportecm.com/${item.Key}`,
          });
        });

        this.setState({
          archivos_s3: items,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }
  renderS3Files() {
    if (this.state.archivos_s3.length === 0) {
      return (
        <tr>
          <th colSpan="4">No se encontraron archivos</th>
        </tr>
      );
    } else {
      return this.state.archivos_s3.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.key.replace(`files/general`, "")}</td>
            <td>
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.url}
              </a>
            </td>
            <td>
              {this.state.all_permissions ? (
                <>
                  <button
                    className="btn btn-danger"
                    data-toggle="modal"
                    data-target="#delete-modalgemeral"
                    onClick={() => {
                      this.setState({
                        key_to_delete: item.key,
                      });
                    }}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                </>
              ) : (
                <></>
              )}
            </td>
          </tr>
        );
      });
    }
  }

  render() {
    return (
      <>
        <div className="mt-5 mb-3">
          <div className="row">
            <div className="col-md-12">
              {this.state.has_notification ? this.state.notification : null}
            </div>
          </div>
          <div></div>

          <div className="row">
            <div className="col-md-12">
              <FilePond
                files={this.state.archivos_cargar}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    s3_object
                      .upload({
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: `files/general/${file.name}`,
                        Body: file,
                        ACL: "public-read",
                        ContentType: file.type,
                      })
                      .promise()
                      .then((response) => {
                        load(response.Key);
                        this.loadS3Files();
                      })
                      .catch((error) => {
                        error("Hubo un error cargando el archivo");
                      });
                  },
                }}
                onupdatefiles={(items) => {
                  this.setState({
                    archivos_cargar: items.map((fileItem) => fileItem.file),
                  });
                }}
                onerror={(error) => {
                  this.setState({
                    has_error: true,
                    error: error.main,
                  });
                }}
                allowMultiple={true}
                labelIdle="Arrastra o carga archivos publicos"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">URL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>{this.renderS3Files()}</tbody>
              </table>
            </div>
          </div>
          <div
            className="modal fade"
            id="delete-modalgemeral"
            data-backdrop="static"
            data-keyboard="true"
            tabIndex="-1"
            aria-labelledby="delete-modalgemeral"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="delete-modalgemeral-label">
                    Eliminar archivo
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    ¿Seguro que deseas eliminar el item{" "}
                    <strong>
                      {this.state.key_to_delete.replace("files/general", "")}
                    </strong>
                    ?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn cemaco-button mr-auto"
                    data-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => {
                      s3_object
                        .deleteObject({
                          Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                          Key: this.state.key_to_delete,
                        })
                        .promise()
                        .then((response) => {
                          this.loadS3Files();
                          this.setState({
                            has_notification: true,
                            notification: (
                              <div className="alert alert-success">
                                El archivo{" "}
                                <strong>
                                  {this.state.key_to_delete.replace(
                                    "files/general",
                                    ""
                                  )}
                                </strong>{" "}
                                se elimino correctamente.
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="alert"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                            ),
                          });
                        })
                        .catch((error) => {
                          this.setState({
                            has_notification: true,
                            notification: (
                              <div className="alert alert-danger">
                                Hubo un error eliminando el archivo{" "}
                                <strong>
                                  {this.state.key_to_delete.replace(
                                    "files/",
                                    ""
                                  )}
                                </strong>
                              </div>
                            ),
                          });
                        });
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchivosPublicos;
