import axios from "axios";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import DropdownList from "react-widgets/DropdownList";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import parse from "html-react-parser";
import { Auth } from "aws-amplify";
import es from 'date-fns/locale/es'
import addDays from 'date-fns/addDays'
import DatePicker, {registerLocale,setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
setDefaultLocale('es')
registerLocale('es',es);
class Masivos extends Component {
    state = {
        templates: [],
        telefonos: [],
        opcionMensajeProgramado:"mensajeInmediato",
        dropdown_busy: true,
        dropdown_disabled: false,
        dropdown_telefono_busy: true,
        dropdown_telefono_disabled: false,

        selected_template: {
            Id: "",
            Name: "",
            Description: "",
            FileField: "",
            FileType: "",
        },
        selected_telefono: {
            Phone: "",
            Name: "",
        },
        startDate: new Date(),
        is_template_selected: false,
        is_telefono_selected: false,

        template_info_loaded: false,
        telefono_info_loaded: false,

        template_notification: <></>,
        template_load: <></>,
        template_fields: [],
        puede_enviar_mensaje: false,
        categorias:[],
        subcategorias:[],
        categoriaSelect: '',
        loader: (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        ),
        is_loading: false,
        selected_fields: new Set(),

        page_notification: "",
        show_page_notification: false,

        archivo: [],
        notificacion: "",
        canProcess: false,
        fields_file: [],
        notification: <div></div>,
        columns: [],

        loaded_file: false,
        is_loading_file: false,
        sending_request: false,

        dropdowns_selected: {},
        loggedUser: "",
        estructuraTemplate:"",
     
    };
    
    constructor(props) {
        super(props);
        this.renderTemplateNotificationSelection =
            this.renderTemplateNotificationSelection.bind(this);

        this.loadExcel = this.loadExcel.bind(this);
        this.sendInfo = this.sendInfo.bind(this);
        this.formatinfo = this.formatinfo.bind(this);
        this.renderTelefonos = this.renderTelefonos.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cambiarEstadoMensaje=this.cambiarEstadoMensaje.bind(this);
        this.apicategoriaslistar=this.apicategoriaslistar(this);
        this.renderSearchTemplatesBusiness=this.renderSearchTemplatesBusiness.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                this.setState({
                    loggedUser: user.username,
                });
            })
            .catch((error) => {
                console.log(error);
            });
        Auth.currentSession().then(session => {
            var token = session.getIdToken().getJwtToken()
            var headers = {'Authorization' : 'Bearer'+ ' ' + token}
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/template?limit=200`,{headers})
                
                .then((data) => {
                   
                    this.setState({
                        templates: data.data.templates,
                        dropdown_busy: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.setState({
                        dropdown_busy: false,
                    });
                });
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/telefono?limit=200`,{headers})
                .then((data) => {
                 
                    this.setState({
                        telefonos: data.data.telefonos,
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.setState({
                        dropdown_telefono_busy: false,
                        getting_data: false,
                    });
                });
        })
    }
    renderSearchTemplatesBusiness()
    {
      const headers={
        Authorization:  `Bearer ${process.env.REACT_APP_TOKEN_GRAP_FACEBOOKBU}`,
      }
      axios
      .get(`https://graph.facebook.com/v17.0/${process.env.REACT_APP_USER_GRAP_FACEBOOK}/message_templates?status=APPROVED&name=${this.state.selected_template.Id}`,{ headers })
      .then(async (respuseta) => {
          
            const {data} = respuseta.data;
            data[0].components.forEach(e => {
                   if(e.type==="BODY")
                   {
                  
                    this.setState({
                      estructuraTemplate: e.text
                    })
                   }
            });
           
      });
    }
    apicategoriaslistar()
    {
        Auth.currentSession().then((session) => {
            var headers = {
              Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
            };
            let data ={
                categoria: ""
            }
            axios
              .post(`${process.env.REACT_APP_API_URL}/f/categorias`,
              data,{headers},
              )
              .then((data) => {
                this.setState({
                    categorias: data.data.respuesta
                })        
              })
              .catch((error) => {
                console.log(error);
              })
          });
    }
    apiSubcategorias(categoria)
    {
        Auth.currentSession().then((session) => {
            var headers = {
              Authorization: "Bearer" + " " + session.getIdToken().getJwtToken(),
            };
            let data ={
                categoria: categoria
            }
            axios
              .post(`${process.env.REACT_APP_API_URL}/f/categorias`,
              data,{headers},
              )
              .then((data) => {
                    this.setState({
                        subcategorias: data.data.respuesta
                    }) 
              })
              .catch((error) => {
                console.log(error);
              })
          });
    }
    listarSubCategorias(id)
    {
        this.apiSubcategorias(id.categoria)
        this.setState({
            categoriaSelect: id.categoria
        })
    }
    renderTelefonos = ({ item }) => {
        return (
            <>
                <span className="mt-1 mb-1">
                    <strong>{item.Name}</strong> ({item.Phone})
                </span>
            </>
        );
    };

    renderTemplates = ({ item }) => {
        return (
            <>
                {item.hasOwnProperty("type") ? (
                    <Link
                        className="btn btn-lg btn-block cemaco-button-green mt-1 mb-1"
                        to="/template"
                        push="true"
                    >
                        {item.Name}
                    </Link>
                ) : (
                    <span className="mt-1 mb-1">
                        <strong>{item.Name}</strong> ({item.Id})
                    </span>
                )}
            </>
        );
    };

    renderCategorias()
    {

        return ( <>
            <label className="font-weight-bold">Seleccione Categoría Template</label>
            <DropdownList
            data={this.state.categorias}
            dataKey="id"
            textField="categoria"
            placeholder="Seleccione una categoria"
            renderListItem={ ({ item }) => (
                <span className="mt-1 mb-1">
                <strong>{item.categoria}</strong>
                </span>
              )}
            onSelect={async (id) => {
                this.listarSubCategorias(id);
            }} />
            
            </>
        );
    }

    renderSubCategorias()
    {
        return ( <>
            <label className="font-weight-bold">Seleccione Template</label>
            <DropdownList
            data={this.state.subcategorias}
            dataKey="Id"
            textField="Name"
            placeholder="Seleccione una categoria principal"
            renderListItem={ ({ item }) => (
                <span className="mt-1 mb-1">
                <strong>{item.Name}</strong>({item.Id})
                </span>
              )}
            onSelect={async (selected_template) => {         
                await this.setState({
                    selected_template: selected_template,
                    dropdown_disabled: true,
                    is_template_selected: true,
                    template_info_loaded: false,
                    is_loading: true,
                });
                this.renderTemplateNotificationSelection();
                this.renderSearchTemplatesBusiness();
            }} />
            
            </>
        );
    }

    renderTemplateNotificationSelection = () => {
        if (!this.state.is_template_selected) return <></>;
        Auth.currentSession().then(session=>{
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": 'Bearer'+ ' ' + token}
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/f/template/fields/${this.state.selected_template.Id}`,{headers}
                )
       
                .then((data) => {
       
                    let notificacion = "";
                    let puede_enviar_mensaje = false;

                    if (this.state.selected_template.FileType!=="")  {
                        if (data.data.Fields.length === 0) {
                        notificacion = (
                            <div className="alert alert-danger" role="alert">
                                {this.state.selected_template.FileType}
                                El template{" "}
                                <strong>{this.state.selected_template.Id}</strong>{" "}
                                no tiene campos, para poder enviar mensajes con esta
                                plantilla debe{" "}
                                <Link
                                    to={`/template/${this.state.selected_template.Id}/field`}
                                    className="alert-link"
                                >
                                    crear campos
                                </Link>
                                .
                            </div>
                        );
                    } else{
                        notificacion = (
                            <div className="alert alert-info" role="alert">
                                <h4>Información importante</h4>
                                <p>
                                    Te en cuenta que el Excel puede variar
                                    dependiendo del template de FreshDesk que se
                                    utilizará.
                                </p>
                                {this.state.selected_template.FileField !== "" ? (
                                    <p>
                                        El campo{" "}
                                        <strong>
                                            {this.state.selected_template.FileField}
                                        </strong>{" "}
                                        del template debe contener un link que
                                        contenga archivo de tipo{" "}
                                        <strong>
                                            {this.state.selected_template.FileType}
                                        </strong>
                                        .
                                    </p>
                                ) : null}
                            </div>
                        );
                        puede_enviar_mensaje = true;
                    } }else {
                        notificacion = (
                            <div className="alert alert-info" role="alert">
                                <h4>Información importante</h4>
                                <p>
                                    Te en cuenta que el Excel puede variar
                                    dependiendo del template de FreshDesk que se
                                    utilizará.
                                </p>
                                {this.state.selected_template.FileField !== "" ? (
                                    <p>
                                        El campo{" "}
                                        <strong>
                                            {this.state.selected_template.FileField}
                                        </strong>{" "}
                                        del template debe contener un link que
                                        contenga archivo de tipo{" "}
                                        <strong>
                                            {this.state.selected_template.FileType}
                                        </strong>
                                        .
                                    </p>
                                ) : null}
                            </div>
                        );
                        puede_enviar_mensaje = true;
                    }
                    let fields = data.data.Fields;
                    fields.push({
                        Id: "Phone",
                        Name: "Phone",
                        Position: -1,
                    });

                    this.setState({
                        template_notification: notificacion,
                        template_fields: fields,
                        puede_enviar_mensaje: puede_enviar_mensaje,
                    });
                })
                .catch((error) => {
                    this.setState({
                        template_notification: <h1>Error</h1>,
                    });
                })
                .finally(() => {
                    this.setState({
                        template_info_loaded: true,
                        dropdown_disabled: false,
                        is_loading: false,
                    });
                });
            })
    };

    loadExcel = () => {
        if (this.state.archivo.length > 0) {
            this.setState({
                is_loading_file: true,
                show_page_notification: false,
            });
            readXlsxFile(this.state.archivo[0])
                .then((rows) => {
                    try {
                        let fields = [];
                        let columns = [];

                        columns.push(
                            ...this.state.template_fields.map(function (k) {
                                return k.Name;
                            })
                        );
                        rows.forEach((field, index) => {
                            //if (index >= 1) {
                            if (
                                this.state.template_fields.length !==
                                field.length
                            ) {
                                this.state.template_fields
                                    .map(function (k) {
                                        return k.Name;
                                    })
                                    .join(", ");

                                throw new Error(
                                    `Asegurate que tu documento contenga <b>${this.state.template_fields.length} columnas</b>. Ahora solo contiene ${field.length}`
                                );
                            }

                            field.forEach((f, i) => {
                                if (f instanceof Date) {
                                    field[i] = moment(new Date(f))
                                        .add(1, "days")
                                        .format("DD/MM/YYYY");
                                }
                            });
                            fields.push(field);
                            //}
                        });

                        this.setState({
                            fields_file: fields,
                            columns: columns,
                        });
                    } catch (error) {
                        let mensaje = "Hubo un error inesperado";
                        if (error.message) mensaje = error.message;

                        this.setState({
                            show_page_notification: true,
                            page_notification: (
                                <div className="alert alert-danger">
                                    {parse(mensaje)}
                                </div>
                            ),
                        });
                    }
                })
                .finally(() => {
                    this.setState({
                        is_loading_file: false,
                        loaded_file: true,
                    });
                });
        }
    };

    formatinfo = () => {
        return new Promise((resolve, reject) => {
            try {
                const all = this.state.fields_file;
                const info = [];

                all.forEach((el, k) => {
                    const obj = {};
                    el.forEach((field, key) => {

                    
                        if(typeof field == 'number')
                        {
                            obj[this.state.columns[key]] = String(field);
                        }else{
                            obj[this.state.columns[key]] = field;
                        }
                       
                    });

                    info.push(obj);
                });

                resolve(info);
            } catch (error) {
                reject(error);
            }
        });
    };
    
    sendInfo = () => {
        this.setState({
            show_page_notification: false,
        });
        if (
            Object.keys(this.state.dropdowns_selected).length <
            this.state.columns.length
        ) {
            this.setState({
                show_page_notification: true,
                page_notification: (
                    <div className="alert alert-danger">
                        Se deben mapear todos los campos para enviar los
                        mensajes
                    </div>
                ),
            });
        } else if (!this.state.is_telefono_selected) {
            this.setState({
                show_page_notification: true,
                page_notification: (
                    <div className="alert alert-danger">
                        Se debe seleccionar un numero de telefono
                    </div>
                ),
            });
        } else {
           
            let conversionHora = moment(this.state.startDate).format('HH:mm:ss') 
            let conversionFecha = moment(this.state.startDate).format('DD-MM-YYYY');
            let dataMensaje=[];
            if(this.state.opcionMensajeProgramado === 'mensajeprogramado')
            {

                    this.setState({
                        sending_request: true,
                    });
                    this.formatinfo()
                    .then((res) => res.slice(1))
                    .then((res)=>{
                            res.map(x=>{
                                Object.keys(x).map(o => {
                                     if(o==="Phone")
                                     {
                                        x.Phone="+502"+x["Phone"]
                                     }
                         
                                     
                                })
                                dataMensaje=[...dataMensaje,x]
                            })
                     
                         Auth.currentSession().then(session=>{
                            var token = session.getIdToken().getJwtToken()
            
                            var headers = {"Authorization": 'Bearer' + ' ' +token}
                            axios
                            .post(
                                `${process.env.REACT_APP_API_URL}/f/mensajes/programados`,
                                {
                                    Template_id: this.state.selected_template.Id,
                                    numero_telefono: this.state.selected_telefono.Phone,
                                    Messages:  JSON.stringify([...dataMensaje]),
                                    User: this.state.loggedUser,
                                    Fecha:conversionFecha,
                                    Hora:conversionHora,
                                    usuario: this.state.loggedUser,
                                },
                                {headers}
                                
                            )
                            .then((res) => {
                                this.setState({
                                    page_notification: (
                                        <div className="alert alert-success">
                                            {res.data.message}
                                        </div>
                                    ),
                                });
                            })
                            .catch((err) => {
                                this.setState({
                                    page_notification: (
                                        <div className="alert alert-success">
                                            {err.response.data.message}
                                        </div>
                                    ),
                                });
                            })
                            .finally(() => {
                                this.setState({
                                    sending_request: false,
                                    show_page_notification: true,
                                });
                            });
                         
                         })
                    }).catch((err) => {
                        this.setState({
                            page_notification: (
                                <div className="alert alert-success">
                                    Hubo un error dando formato a la información
                                    para enviar
                                </div>
                            ),
                        });
                    })
                    .finally(() => {
                        this.setState({
                            sending_request: false,
                            show_page_notification: true,
                            archivo: [],
                            fields_file: [],
                            columns: [],
                            loaded_file: false,
                            is_loading_file: false,
                            dropdowns_selected: {},
                        });
                    });
                 
                  
            }else{

                this.setState({
                    sending_request: true,
                });
                this.formatinfo()
                    .then((res) => res.slice(1))
                    .then((res) => {
                   
                        Auth.currentSession().then(session=>{
                            var token = session.getIdToken().getJwtToken()
                            var headers = {"Authorization": 'Bearer'+ ' ' +token}
                        
                            axios
                                .post(
                                    `${process.env.REACT_APP_API_URL}/f/mensajes/${this.state.selected_template.Id}`,
                                    {
                                        FromPhone: this.state.selected_telefono.Phone,
                                        Messages: [...res],
                                        User: this.state.loggedUser,
                                    },
                                    {headers}
                                )
                                .then((res) => {
                                    this.setState({
                                        page_notification: (
                                            <div className="alert alert-success">
                                                {res.data.message}
                                            </div>
                                        ),
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        page_notification: (
                                            <div className="alert alert-success">
                                                {err.response.data.message}
                                            </div>
                                        ),
                                    });
                                })
                                .finally(() => {
                                    this.setState({
                                        sending_request: false,
                                        show_page_notification: true,
                                    });
                                });
                        })
                    })
                    .catch((err) => {
                        this.setState({
                            page_notification: (
                                <div className="alert alert-success">
                                    Hubo un error dando formato a la información
                                    para enviar
                                </div>
                            ),
                        });
                    })
                    .finally(() => {
                        this.setState({
                            sending_request: false,
                            show_page_notification: true,
                            archivo: [],
                            fields_file: [],
                            columns: [],
                            loaded_file: false,
                            is_loading_file: false,
                            dropdowns_selected: {},
                        });
                    });

            }

           
        }
    };


    handleChange(date)
    {
      
        this.setState({
            startDate: date
        })
    }
    fechasumando(dias)
    {
        const mi_fecha= new Date();

        return new mi_fecha.setDate(dias)
    }
    filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        
        return currentDate.getTime() < selectedDate.getTime();
    }
    cambiarEstadoMensaje(event)
    {
       
        this.setState({
            opcionMensajeProgramado: event.target.value
        })
    }
    render() {
        return (
            <div className="row mt-5 mb-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center">Enviar mensajes</h1>
                           
                        </div>
                    </div>
                    {this.state.show_page_notification ? (
                        <div className="row mt-5 mb-3">
                            <div className="col-md-12">
                                {this.state.page_notification}
                            </div>
                        </div>
                    ) : null}
                    <div className="row mt-3">
                        <div className="col-md-6">
                            {this.renderCategorias()}
                        </div>
                        <div className="col-md-6">
                            {this.renderSubCategorias()}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <DropdownList
                                placeholder="Seleccione un número de telefono (numero para enviar el mensaje)"
                                disabled={this.state.dropdown_telefono_disabled}
                                autoFocus="False"
                                busy={this.state.dropdown_telefono_busy}
                                renderListItem={this.renderTelefonos}
                                dataKey="Phone"
                                textField="Name"
                                data={this.state.telefonos}
                                groupBy={(template) =>
                                    template.hasOwnProperty("type")
                                }
                                renderListGroup={({ group }) => {
                                    return group ? <hr /> : <></>;
                                }}
                                onSelect={async (selected_telefono) => {
                                    this.setState({
                                        selected_telefono: selected_telefono,
                                        dropdown_telefono_disabled: false,
                                        is_telefono_selected: true,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {this.state.template_info_loaded &&
                    this.state.is_template_selected ? (
                        <>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    {this.state.template_notification}
                                </div>
                            </div>
                            {this.state.puede_enviar_mensaje ? (
                                <div className="row mt-3">
                                    <div className="col-md-12">  
                                    <div class="alert alert-dark" role="alert">
                                        <h4>Contenido Template</h4>
                                    {this.state.estructuraTemplate}
                                    </div>
                                  
                                        <FilePond
                                            allowMultiple={false}
                                            onupdatefiles={(files) => {
                                                this.setState({
                                                    archivo: files.map(
                                                        (item) => item.file
                                                    ),
                                                    notification: <div></div>,
                                                    canProcess: false,
                                                    fields_file: [],
                                                    columns: [],
                                                });
                                            }}
                                            files={this.state.archivo}
                                            acceptedFileTypes={[
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                            ]}
                                            labelFileTypeNotAllowed="El tipo de archivo no esta admitido, selecciona un Excel xlsx"
                                            fileValidateTypeLabelExpectedTypesMap={{
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                                    "Excel",
                                            }}
                                            labelIdle="Arrastra o carga tu archivo aqui"
                                            onremovefile={() => {
                                                this.setState({
                                                    columns: [],
                                                    fields_file: [],
                                                    archivo: [],
                                                });
                                            }}
                                        />
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.notification}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                        
                                       
                                            {!this.state.is_loading_file ||
                                            !this.state.sending_request ? (
                                                this.state.loaded_file ? (
                                        <div className="col-12 d-flex">
                                        <div className="col-5">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" value="mensajeInmediato" name="mensajeInmediato" id="mensajeinmediato"  
                                                 checked={this.state.opcionMensajeProgramado === "mensajeInmediato"}
                                                 onChange={this.cambiarEstadoMensaje}
                                                />
                                                <label className="form-check-label" for="mensajeinmediato">
                                                    Mensaje Inmediato
                                                </label>
                                                </div>
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" value ="mensajeprogramado" name="mensajeprogramado" id="mensajeprogramado" 
                                                  checked={this.state.opcionMensajeProgramado === "mensajeprogramado"}
                                                  onChange={this.cambiarEstadoMensaje}
                                                />
                                                <label className="form-check-label" for="mensajeprogramado">
                                                    Mensaje Programado
                                                </label>
                                                </div>
                                            </div>
                                   
                                      <div className="col-6">
                                      {this.state.opcionMensajeProgramado === "mensajeprogramado" ? (
                                            <DatePicker 
                                            className="form-control w-50"
                                            selected={this.state.startDate } 
                                            onChange={this.handleChange} 
                                            timeInputLabel="Time:"
                                            name="startDate" 
                                            dateFormat="MM/dd/yyyy hh:mm aa" 
                                            showTimeSelect
                                            locale="es"
                                            timeIntervals={5}
                                            filterTime={this.filterPassedTime}
                                            minDate={new Date()}
                                            />
                                        ): (
                                        <>
                                        </>
                                        )}

                                      </div>
                                                <div className="col-2">
                                                <button
                                                        type="button"
                                                        className="btn btn-primary cemaco-button"
                                                        onClick={this.sendInfo}
                                                    >
                                                        Enviar
                                                    </button>
                                                </div>
                                                    </div>
                                                   
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn cemaco-button-green"
                                                        onClick={this.loadExcel}
                                                    >
                                                        Cargar
                                                    </button>
                                                )
                                            ) : (
                                                <button
                                                    className="btn btn-primary"
                                                    disabled
                                                >
                                                    <span
                                                        className="spinner-grow spinner-grow-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="sr-only">
                                                        Procesando...
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                     
                                    {this.state.fields_file.length > 0 && (
                                        
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    {this.state.fields_file[0].map(
                                                        (field, index) => {
                                                            return (
                                                                <td key={index}>
                                                               
                                                                    <DropdownList
                                                                        required
                                                                        dataKey="Id"
                                                                        textField="Name"
                                                                        placeholder="Selecciona un campo"
                                                                        data={
                                                                            this
                                                                                .state
                                                                                .template_fields
                                                                        }
                                                                        onChange={(
                                                                            v
                                                                        ) => {
                                                                            let dropdowns_selected =
                                                                                this
                                                                                    .state
                                                                                    .dropdowns_selected;
                                                                            dropdowns_selected[
                                                                                `${index}`
                                                                            ] =
                                                                                {
                                                                                    selected: true,
                                                                                    [`${index}`]:
                                                                                        v.Id,
                                                                                };
                                                                            let col_set =
                                                                                this
                                                                                    .state
                                                                                    .selected_fields;
                                                                            col_set.add(
                                                                                v.Id
                                                                            );
                                                                            let columns =
                                                                                this
                                                                                    .state
                                                                                    .columns;
                                                                            columns[
                                                                                index
                                                                            ] =
                                                                                v.Name;
                                                                            this.setState(
                                                                                {
                                                                                    columns:
                                                                                        columns,
                                                                                    selected_fields:
                                                                                        col_set,
                                                                                    dropdowns_selected:
                                                                                        dropdowns_selected,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fields_file.map(
                                                    (field, index) => (
                                                        <tr key={index}>
                                                            {field.map(
                                                                (f, i) => (
                                                                    <td key={i}>
                                                                        {f}
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                    
                                </div>
                            ) : null}
                        </>
                    ) : this.state.is_loading ? (
                        <div className="row mt-5">
                            <div className="col-md-12">{this.state.loader}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Masivos;
