import axios from "axios";
import React, { Component } from "react";
import DropdownList from "react-widgets/DropdownList";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import {Auth} from "aws-amplify";

class Logs extends Component {
    state = {
        templates: [],
        dropdown_busy: true,
        dropdown_disabled: false,
        dropdown_telefono_busy: true,
        dropdown_telefono_disabled: false,
        selected_template: {
            Id: "",
            Name: "",
            Description: "",
        },
        selected_telefono: {
            Phone: "",
            Name: "",
        },
        is_template_selected: false,
        is_telefono_selected: false,
        dropdowns_selected: {},
        start_date: "",
        start_date_selected: false,
        end_date: "",
        end_date_selected: "",
        log_list: [],
        log_page_start_keys: [""],
        obtuvo_data: false,
        show_page_notification: false,
        page_notification: null,
        telefono_cliente: "",
        telefono_cliente_selected: false,
        selected_status: "Todos",
        selected_status_selected: false,
        username_filter: "",
        username_filter_selected: false,
        actual_page: 1,
        is_loading_email: false,
        is_loading_search: false,
        log_consultar: {},
        log_consultar_result: {},
    };

    constructor(props) {
        super(props);
        this.buscarLogs = this.buscarLogs.bind(this);
        this.renderTableContent = this.renderTableContent.bind(this);
        this.renderTelefonos = this.renderTelefonos.bind(this);
        this.renderTemplates = this.renderTemplates.bind(this);
        this.getOtherPage = this.getOtherPage.bind(this);
    }

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        Auth.currentSession().then(session=>{
            var headers = {"Authorization": 'Bearer'+' '+  session.getIdToken().getJwtToken()}
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/template?limit=200`,{headers})
                .then((data) => {
                    let template_list = [];
                    template_list = data.data.templates;
                    this.setState({
                        templates: template_list,
                        dropdown_busy: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            axios
                .get(`${process.env.REACT_APP_API_URL}/f/telefono?limit=200`,{headers})
                .then((data) => {
                    this.setState({
                        telefonos: data.data.telefonos,
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.setState({
                        dropdown_telefono_busy: false,
                        getting_data: false,
                    });
                });
            })
    }

    renderTelefonos = ({ item }) => {
        return (
            <>
                <span className="mt-1 mb-1">
                    <strong>{item.Name}</strong> ({item.Phone})
                </span>
            </>
        );
    };

    renderTemplates = ({ item }) => {
        return (
            <span className="mt-1 mb-1">
                <strong>{item.Name}</strong> ({item.Id})
            </span>
        );
    };

    buscarLogs() {
        this.setState({
            obtuvo_data: false,
            log_list: [],
            show_page_notification: false,
            log_page_start_keys: [""],
            actual_page: 1,
            is_loading_search:true
        });
        let get_params = {};
        if (this.state.start_date_selected)
            get_params["fecha_inicio"] = this.state.start_date;
        if (this.state.end_date_selected)
            get_params["fecha_fin"] = this.state.end_date;
        if (this.state.is_template_selected)
            get_params["template_id"] = this.state.selected_template.Id;
        if (this.state.is_telefono_selected)
            get_params["origin_phone"] = this.state.selected_telefono.Phone;
        if (this.state.telefono_cliente_selected)
            get_params["client_phone"] = this.state.telefono_cliente;
        if (this.state.username_filter_selected)
            get_params["username"] = this.state.username_filter;
        if (this.state.selected_status_selected) {
            if (this.state.selected_status !== "Todos")
                get_params["is_error"] =
                    this.state.selected_status !== "Exitosos";
        }
        Auth.currentSession().then(session=>{
            var headers = {"Authorization": 'Bearer'+' '+ session.getIdToken().getJwtToken()}
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/logs`, {
                    params: get_params,headers,
                })
                .then((data) => {
                    let start_keys = this.state.log_page_start_keys;
                    start_keys.push(data.data.start_key);
                    this.setState({
                        log_page_start_keys: start_keys,
                        actual_page: 1,
                        log_list: data.data.Items,
                    });
                })
                .catch((error) => {
                    this.setState({
                        show_page_notification: true,
                        page_notification: (
                            <div className="alert alert-danger">
                                Hubo un error obteniendo la informacion solicitada
                            </div>
                        ),
                    });
                })
                .finally(() => {
                    this.setState({
                        obtuvo_data: true,
                        is_loading_search:false,
                    });
                });
            })
    }

    getOtherPage(es_anterior) {
        this.setState({
            obtuvo_data: false,
        });
        let get_params = {};
        if (this.state.start_date_selected)
            get_params["fecha_inicio"] = this.state.fecha_inicio;
        if (this.state.end_date_selected)
            get_params["fecha_fin"] = this.state.end_date;
        if (this.state.is_template_selected)
            get_params["template_id"] = this.state.selected_template.Id;
        if (this.state.is_telefono_selected)
            get_params["origin_phone"] = this.state.selected_telefono.Phone;
        if (this.state.telefono_cliente_selected)
            get_params["client_phone"] = this.state.telefono_cliente;
        if (this.state.username_filter_selected)
            get_params["username"] = this.state.username_filter;
        if (this.state.selected_status_selected) {
            if (this.state.selected_status !== "Todos")
                get_params["is_error"] =
                    this.state.selected_status !== "Exitosos";
        }
        let posicion_key = es_anterior
            ? this.state.actual_page - 2
            : this.state.actual_page;
        get_params["start_key"] = this.state.log_page_start_keys[posicion_key];
        console.log(this.state.log_page_start_keys[posicion_key]);
        Auth.currentSession().then(session=>{
            var headers={"Authorization": 'Bearer'+' '+  session.getIdToken().getJwtToken()}
            axios
                .get(`${process.env.REACT_APP_API_URL}/f/logs`, {
                    params: get_params,headers
                })
                .then((data) => {
                    let start_keys = this.state.log_page_start_keys;
                    let to_page = es_anterior
                        ? this.state.actual_page - 1
                        : this.state.actual_page + 1;
                    if (!es_anterior) {
                        start_keys.push(data.data.start_key);
                    }
                    console.log(start_keys);
                    this.setState({
                        log_page_start_keys: start_keys,
                        actual_page: to_page,
                        log_list: data.data.Items,
                    });
                })
                .catch((error) => {
                    this.setState({
                        show_page_notification: true,
                        page_notification: (
                            <div className="alert alert-danger">
                                Hubo un error obteniendo la informacion solicitada
                            </div>
                        ),
                    });
                })
                .finally(() => {
                    this.setState({
                        obtuvo_data: true,
                    });
                });
            })
    }
    generarReporte = async()=>{
        this.setState({
            show_page_notification: false,
            is_loading_email: true  
        })
        try{
            var get_params={}
            if (this.state.start_date_selected)
                get_params["fecha_inicio"] = this.state.start_date;
            if (this.state.end_date_selected)
                get_params["fecha_fin"] = this.state.end_date;
            if (this.state.is_template_selected)
                get_params["template_id"] = this.state.selected_template.Id;
            if (this.state.is_telefono_selected)
                get_params["origin_phone"] = this.state.selected_telefono.Phone;
            if (this.state.telefono_cliente_selected)
                get_params["client_phone"] = this.state.telefono_cliente;
            if (this.state.username_filter_selected)
                get_params["username"] = this.state.username_filter;
            if (this.state.selected_status_selected) {
                if (this.state.selected_status !== "Todos")
                    get_params["is_error"] =
                        this.state.selected_status !== "Exitosos";
            }
            get_params["correos"] = (await Auth.currentAuthenticatedUser()).attributes.email
            var currentSession = (await Auth.currentSession())
            var {status,data} = await axios.post(`${process.env.REACT_APP_API_URL}/f/logs/export`,{},{params:get_params,headers:{"Authorization": 'Bearer'+' '+  currentSession.getIdToken().getJwtToken()}})
            if(status===200){
                this.setState({
                    show_page_notification: true,
                    page_notification: (
                        <div className="alert alert-success">
                            {data.message}
                        </div>
                    ),
                });
            }
            
        }
        catch(ex){
            if(ex.isAxiosError){
                this.setState({
                    
                    show_page_notification: true,
                    page_notification: (
                        <div className="alert alert-danger">
                            {ex.data?ex.data.message:"Hubo un error obteniendo la informacion solicitada"}
                        </div>
                    ),
                });
            }
        }
        this.setState({
            is_loading_email: false  
        })
    }
    renderTableContent() {
        if (this.state.log_list.length > 0) {
            return this.state.log_list.map((item, index) => {
                return (
                    <tr
                        key={index}
                        data-toggle="modal"
                        data-target="#consulta-modal"
                        onClick={() => {
                            if (this.IsJsonString(item["respuesta"])) {
                                let log_response = JSON.parse(
                                    item["respuesta"]
                                );
                                if (log_response.hasOwnProperty("Respuesta"))
                                    log_response = log_response["Respuesta"];
                                if (log_response.hasOwnProperty("link")) {
                                Auth.currentSession().then(session=>{
                                    var headers = {"Authorization": 'Bearer'+' '+  session.getIdToken().getJwtToken()}
                                    axios
                                        .get(
                                            `${process.env.REACT_APP_API_URL}/f/freshchat/consultas?url=https://api.freshchat.com${log_response["link"]["href"]}`,
                                            {headers}
                                        )
                                        .then((data) => {
                                            console.log(data.data);
                                            this.setState({
                                                log_consultar: item,
                                                log_consultar_result: data.data,
                                            });
                                        });
                                    })
                                } else {
                                    this.setState({
                                        log_consultar: item,
                                        log_consultar_result: null,
                                    });
                                }
                            } else {
                                this.setState({
                                    log_consultar: item,
                                    log_consultar_result: null,
                                });
                            }
                        }}
                    >
                        <td>{item["para"]}</td>
                        <td>{item["template_id"]}</td>
                        <td>{item["desde"]}</td>
                        <td>{item["response_ok"]}</td>
                        <td>{item["user"]}</td>
                        <td>{item["fecha"]}</td>
                        <td>
                            {this.IsJsonString(item["respuesta"]) ? (
                                <ReactJson
                                    src={JSON.parse(item["respuesta"])}
                                />
                            ) : (
                                item["respuesta"]
                            )}
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr className="text-center">
                    <td colSpan={7}>
                        No se encontraron logs con la informacion ingresada
                    </td>
                </tr>
            );
        }
    }

    render() {
        let log_table = (
            <>
                <table className="table table-hover table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th>Enviado a</th>
                            <th>Template ID</th>
                            <th>Enviado desde</th>
                            <th>Enviado con exito</th>
                            <th>Usuario que envio el mensaje</th>
                            <th>Fecha que se envio el mensaje</th>
                            <th>Respuesta de Freskdesk</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableContent()}</tbody>
                </table>
            </>
        );
        let paginacion = (
            <>
                <div className="row">
                    <div className="col-6 d-flex justify-content-start">
                        <ul className="pagination">
                            <li
                                className={
                                    "page-item" +
                                    (this.state.actual_page === 1
                                        ? " disabled"
                                        : "")
                                }
                            >
                                <Link
                                    className="page-link"
                                    to="#"
                                    onClick={() => {
                                        this.getOtherPage(true);
                                    }}
                                >
                                    Anterior
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <ul className="pagination">
                            <li
                                className={
                                    "page-item" +
                                    (this.state.log_page_start_keys[
                                        this.state.actual_page
                                    ] === ""
                                        ? " disabled"
                                        : "")
                                }
                            >
                                <Link
                                    className="page-link"
                                    to="#"
                                    onClick={() => {
                                        this.getOtherPage(false);
                                    }}
                                >
                                    Siguente
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
        return (
            <>
                <div className="container">
                    <div className="row mt-5 mb-3">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="text-center">Logs</h1>
                                </div>
                            </div>
                            {this.state.show_page_notification ? (
                                <div className="row mt-5 mb-3">
                                    <div className="col-md-12">
                                        {this.state.page_notification}
                                    </div>
                                </div>
                            ) : null}
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div
                                        className="alert alert-info"
                                        role="alert"
                                    >
                                        <p>
                                            Se pueden seleccionar varios filtros
                                            de forma simultanea, pero todos son
                                            opcionales.
                                        </p>
                                    </div>
                                    <div
                                        className="alert alert-warning"
                                        role="alert"
                                    >
                                        <p>
                                            Tomar en cuenta que si se selecciona
                                            una fecha de inicio y no se
                                            selecciona una fecha de fin solo se
                                            buscaran los registros de el dia
                                            seleccionado (desde las 00:00 hasta
                                            las 23:59)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <DropdownList
                                        placeholder="Seleccione un template"
                                        disabled={this.state.dropdown_disabled}
                                        autoFocus={true}
                                        busy={this.state.dropdown_busy}
                                        renderListItem={this.renderTemplates}
                                        dataKey="Id"
                                        textField="Name"
                                        data={this.state.templates}
                                        onSelect={async (selected_template) => {
                                            await this.setState({
                                                selected_template:
                                                    selected_template,
                                                is_template_selected: true,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <DropdownList
                                        placeholder="Seleccione un número de telefono (numero para enviar el mensaje)"
                                        disabled={
                                            this.state
                                                .dropdown_telefono_disabled
                                        }
                                        busy={this.state.dropdown_telefono_busy}
                                        renderListItem={this.renderTelefonos}
                                        dataKey="Phone"
                                        textField="Name"
                                        data={this.state.telefonos}
                                        groupBy={(template) =>
                                            template.hasOwnProperty("type")
                                        }
                                        renderListGroup={({ group }) => {
                                            return group ? <hr /> : <></>;
                                        }}
                                        onSelect={async (selected_telefono) => {
                                            this.setState({
                                                selected_telefono:
                                                    selected_telefono,
                                                dropdown_telefono_disabled: false,
                                                is_telefono_selected: true,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <DatePicker
                                        placeholder="Fecha de inicio"
                                        onChange={(date) => {
                                            let moment_date =
                                                moment(date).format(
                                                    "DD-MM-YYYY"
                                                );
                                            this.setState({
                                                start_date: moment_date,
                                                start_date_selected: true,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <DatePicker
                                        placeholder="Fecha de fin"
                                        onChange={(date) => {
                                            let moment_date =
                                                moment(date).format(
                                                    "DD-MM-YYYY"
                                                );
                                            this.setState({
                                                end_date: moment_date,
                                                end_date_selected: true,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="telefono-cliente"
                                        placeholder="Número de teléfono del cliente"
                                        value={this.state.telefono_cliente}
                                        onChange={(e) => {
                                            if (e.target.value !== "") {
                                                this.setState({
                                                    telefono_cliente:
                                                        e.target.value,
                                                    telefono_cliente_selected: true,
                                                });
                                            } else {
                                                this.setState({
                                                    telefono_cliente: "",
                                                    telefono_cliente_selected: false,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <DropdownList
                                        placeholder="Seleccione el status deseado"
                                        data={[
                                            "Todos",
                                            "Exitosos",
                                            "Con error",
                                        ]}
                                        onSelect={async (selected_status) => {
                                            this.setState({
                                                selected_status:
                                                    selected_status,
                                                selected_status_selected: true,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="usuario-filtro"
                                        placeholder="Usuario que envio el mensaje"
                                        value={this.state.username_filter}
                                        onChange={(e) => {
                                            if (e.target.value !== "") {
                                                this.setState({
                                                    username_filter:
                                                        e.target.value,
                                                    username_filter_selected: true,
                                                });
                                            } else {
                                                this.setState({
                                                    username_filter: "",
                                                    username_filter_selected: false,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-start mt-3"></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-end mt-3">
                                        <button
                                            className="btn cemaco-button"
                                            onClick={this.buscarLogs}
                                        >
                                            {this.state.is_loading_search?
                                            <div class="spinner-border spinner-border-sm text-light" role="status">
                                                <span class="sr-only">Cargando...</span>
                                            </div>:"Buscar"}
                                        </button>
                                        <button
                                            className="btn cemaco-button ml-2"
                                            onClick={this.generarReporte}
                                        >
                                            {this.state.is_loading_email?
                                            <div class="spinner-border spinner-border-sm text-light" role="status">
                                                <span class="sr-only">Cargando...</span>
                                            </div>:"Enviar"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade"
                                id="consulta-modal"
                                data-backdrop="static"
                                data-keyboard="true"
                                tabIndex="-1"
                                aria-labelledby="consulta-modal"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5
                                                className="modal-title"
                                                id="consulta-modal-label"
                                            >
                                                Consultar registro
                                            </h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    Enviando a
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "para"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    Template usado
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "template_id"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    Enviado desde el telefono
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "desde"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    ¿La respuesta fue exitosa?
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "response_ok"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    Usuario que envio el mensaje
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "user"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6 font-weight-bold">
                                                    Fechan que se envio el
                                                    mensaje
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        this.state
                                                            .log_consultar[
                                                            "fecha"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-12 font-weight-bold">
                                                    Respuesta recibida al enviar
                                                    el mensaje
                                                </div>
                                                <div className="col-md-12">
                                                    {this.IsJsonString(
                                                        this.state
                                                            .log_consultar[
                                                            "respuesta"
                                                        ]
                                                    ) ? (
                                                        <ReactJson
                                                            src={JSON.parse(
                                                                this.state
                                                                    .log_consultar[
                                                                    "respuesta"
                                                                ]
                                                            )}
                                                        />
                                                    ) : (
                                                        this.state
                                                            .log_consultar[
                                                            "respuesta"
                                                        ]
                                                    )}
                                                </div>
                                            </div>
                                            {this.state.log_consultar_result ? (
                                                <>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12 font-weight-bold">
                                                            Consulta de la
                                                            conversacion
                                                        </div>
                                                        <div className="col-md-12">
                                                            <ReactJson
                                                                src={
                                                                    this.state
                                                                        .log_consultar_result
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn cemaco-button mr-auto"
                                                data-dismiss="modal"
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 mb-3">
                    <div className="col-md-12">
                        {this.state.obtuvo_data ? log_table : null}
                    </div>
                </div>
                <div className="row mt-5 mb-3">
                    <div className="col-md-12">
                        {this.state.obtuvo_data ? paginacion : null}
                    </div>
                </div>
            </>
        );
    }
}

export default Logs;
