import React, { Component } from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import AWS from "aws-sdk";

class Suplemento extends Component {
    state = {
        archivos_suple: [],
        has_error_suple: false,
        error_suple: "",
        has_notification_suple: false,
        notification_message_suple: "",

        archivos_cuponera: [],
        has_error_cuponera: false,
        error_cuponera: "",
        has_notification_cuponera: false,
        notification_message_cuponera: "",
    };

    render() {
        return (
            <>
                <div className="row mt-5 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="text-center">
                                            Cargar suplemento
                                        </h2>
                                    </div>
                                </div>
                                {this.state.has_notification_suple ? (
                                    <div className="row mt-5 mb-3">
                                        <div className="col-md-12">
                                            {
                                                this.state
                                                    .notification_message_suple
                                            }
                                        </div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <FilePond
                                                    files={
                                                        this.state
                                                            .archivos_suple
                                                    }
                                                    onupdatefiles={(items) => {
                                                        this.setState({
                                                            archivos_suple:
                                                                items.map(
                                                                    (
                                                                        fileItem
                                                                    ) =>
                                                                        fileItem.file
                                                                ),
                                                        });
                                                    }}
                                                    onerror={(error) => {
                                                        this.setState({
                                                            has_error_suple: true,
                                                            error_suple:
                                                                error.main,
                                                        });
                                                    }}
                                                    allowMultiple={false}
                                                    acceptedFileTypes={[
                                                        "application/pdf",
                                                    ]}
                                                    labelFileTypeNotAllowed="El tipo de archivo no esta admitido, selecciona un archivo PDF"
                                                    labelIdle="Arrastra o carga tu archivo aqui"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 d-flex justify-content-end mt-2">
                                                <button
                                                    className="btn cemaco-button"
                                                    onClick={() => {
                                                        if (
                                                            !this.state
                                                                .has_error_suple
                                                        ) {
                                                            try {
                                                                let s3_object =
                                                                    new AWS.S3({
                                                                        accessKeyId:
                                                                            process
                                                                                .env
                                                                                .REACT_APP_AWS_ACCESS,
                                                                        secretAccessKey:
                                                                            process
                                                                                .env
                                                                                .REACT_APP_AWS_SECRET,
                                                                    });
                                                                s3_object.upload(
                                                                    {
                                                                        Bucket: process
                                                                            .env
                                                                            .REACT_APP_AWS_BUCKET_NAME,
                                                                        Key: "revista.pdf",
                                                                        Body: this
                                                                            .state
                                                                            .archivos_suple[0],
                                                                        ACL: "public-read",
                                                                        ContentType:
                                                                            "application/pdf",
                                                                    },
                                                                    (
                                                                        error,
                                                                        data
                                                                    ) => {
                                                                        if (
                                                                            error
                                                                        ) {
                                                                            console.log(
                                                                                error
                                                                            );
                                                                            this.setState(
                                                                                {
                                                                                    has_notification_suple: true,
                                                                                    notification_message_suple:
                                                                                        (
                                                                                            <div className="alert alert-danger">
                                                                                                Hubo
                                                                                                un
                                                                                                error
                                                                                                cargando
                                                                                                el
                                                                                                archivo
                                                                                            </div>
                                                                                        ),
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                {
                                                                                    has_notification_suple: true,
                                                                                    notification_message_suple:
                                                                                        (
                                                                                            <div className="alert alert-success">
                                                                                                El
                                                                                                archivo
                                                                                                se
                                                                                                cargo
                                                                                                con
                                                                                                exito
                                                                                            </div>
                                                                                        ),
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            } catch (e) {
                                                                console.log(e);
                                                                this.setState({
                                                                    has_notification_suple: true,
                                                                    notification_message_suple:
                                                                        (
                                                                            <div className="alert alert-danger">
                                                                                Hubo
                                                                                un
                                                                                error
                                                                                cargando
                                                                                el
                                                                                archivo
                                                                            </div>
                                                                        ),
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                has_notification_suple: true,
                                                                notification_message_suple:
                                                                    (
                                                                        <div className="alert alert-danger">
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .error_suple
                                                                            }
                                                                        </div>
                                                                    ),
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Cargar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h5>Link de descarga del suplemento</h5>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <a
                                        href="https://suplemento.soportecm.com/revista.pdf"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        https://suplemento.soportecm.com/revista.pdf
                                    </a>
                                  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mb-5 mt-5" />
                <div className="row mt-5 mb-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="text-center mb-2">
                                            Cargar la cuponera
                                        </h2>
                                    </div>
                                </div>
                                {this.state.has_notification_cuponera ? (
                                    <div className="row mt-5 mb-3">
                                        <div className="col-md-12">
                                            {
                                                this.state
                                                    .notification_message_cuponera
                                            }
                                        </div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <FilePond
                                                    files={
                                                        this.state
                                                            .archivos_cuponera
                                                    }
                                                    onupdatefiles={(items) => {
                                                        this.setState({
                                                            archivos_cuponera:
                                                                items.map(
                                                                    (
                                                                        fileItem
                                                                    ) =>
                                                                        fileItem.file
                                                                ),
                                                        });
                                                    }}
                                                    onerror={(error) => {
                                                        this.setState({
                                                            has_error_cuponera: true,
                                                            error_cuponera:
                                                                error.main,
                                                        });
                                                    }}
                                                    allowMultiple={false}
                                                    acceptedFileTypes={[
                                                        "application/pdf",
                                                    ]}
                                                    labelFileTypeNotAllowed="El tipo de archivo no esta admitido, selecciona un archivo PDF"
                                                    labelIdle="Arrastra o carga tu archivo aqui"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 d-flex justify-content-end mt-2">
                                                <button
                                                    className="btn cemaco-button"
                                                    onClick={() => {
                                                        if (
                                                            !this.state
                                                                .has_error_cuponera
                                                        ) {
                                                            try {
                                                                let s3_object =
                                                                    new AWS.S3({
                                                                        accessKeyId:
                                                                            process
                                                                                .env
                                                                                .REACT_APP_AWS_ACCESS,
                                                                        secretAccessKey:
                                                                            process
                                                                                .env
                                                                                .REACT_APP_AWS_SECRET,
                                                                    });
                                                                s3_object.upload(
                                                                    {
                                                                        Bucket: process
                                                                            .env
                                                                            .REACT_APP_AWS_BUCKET_NAME,
                                                                        Key: "cuponera.pdf",
                                                                        Body: this
                                                                            .state
                                                                            .archivos_cuponera[0],
                                                                        ACL: "public-read",
                                                                        ContentType:
                                                                            "application/pdf",
                                                                    },
                                                                    (
                                                                        error,
                                                                        data
                                                                    ) => {
                                                                        if (
                                                                            error
                                                                        ) {
                                                                            console.log(
                                                                                error
                                                                            );
                                                                            this.setState(
                                                                                {
                                                                                    has_notification_cuponera: true,
                                                                                    notification_message_cuponera:
                                                                                        (
                                                                                            <div className="alert alert-danger">
                                                                                                Hubo
                                                                                                un
                                                                                                error
                                                                                                cargando
                                                                                                el
                                                                                                archivo
                                                                                            </div>
                                                                                        ),
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                {
                                                                                    has_notification_cuponera: true,
                                                                                    notification_message_cuponera:
                                                                                        (
                                                                                            <div className="alert alert-success">
                                                                                                El
                                                                                                archivo
                                                                                                se
                                                                                                cargo
                                                                                                con
                                                                                                exito
                                                                                            </div>
                                                                                        ),
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            } catch (e) {
                                                                console.log(e);
                                                                this.setState({
                                                                    has_notification_cuponera: true,
                                                                    notification_message_cuponera:
                                                                        (
                                                                            <div className="alert alert-danger">
                                                                                Hubo
                                                                                un
                                                                                error
                                                                                cargando
                                                                                el
                                                                                archivo
                                                                            </div>
                                                                        ),
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                has_notification_cuponera: true,
                                                                notification_message_cuponera:
                                                                    (
                                                                        <div className="alert alert-danger">
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .error_cuponera
                                                                            }
                                                                        </div>
                                                                    ),
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Cargar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h5>Link de descarga de la cuponera</h5>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <a
                                        href="https://suplemento.soportecm.com/cuponera.pdf"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        https://suplemento.soportecm.com/cuponera.pdf
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Suplemento;
