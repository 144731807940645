import axios from "axios"
import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { Auth } from "aws-amplify";

class Telefono extends Component {

    constructor(props) {
        super(props)
        this.state = {
            telefono_info: [],
            is_error: false,
            is_notification: false,
            notification_message: "",
            loading: true,
            telefono_values:{
                Phone: '',
                Name: ''
            },
            telefonoPhone:'',
            telefonoName:'',
            phone_validacion: false,
            name_validation: false,
            return_redirect: false,

            getting_data: false,
            loader: (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        this.renderAllList = this.renderAllList.bind(this)
        this.loadTelefonos = this.loadTelefonos.bind(this)
    }

    componentDidMount() {
        this.loadTelefonos()
    }

    loadTelefonos() {
        this.setState({
            getting_data: true
        })
        Auth.currentSession().then(session=>{
            var token = session.getIdToken().getJwtToken()
            var headers = {"Authorization": 'Bearer'+' '+ token}
            axios.get(
                `${process.env.REACT_APP_API_URL}/f/telefono?limit=200`,{headers}
            ).then(data => {
                this.setState({
                    is_notification: false,
                    telefono_info: data.data
                })
            }).catch(error => {
                this.setState({
                    is_error: true,
                    is_notification: true,
                    notification_message: (
                        <div className="alert alert-danger" role="alert">
                            {error.response.data.message}
                        </div>
                    )
                })
            }).finally(() => {
                this.setState({
                    loading: false,
                    getting_data: false
                })
            })
        })
    }

    renderAllList(){
        try {
            return this.state.telefono_info.telefonos.map((telefono, index) => 
                (
                    <div className="col-md-4" key={index}>
                        <div className="shadow p-5 mb-5 bg-white rounded position-relative">
                            <h5>{telefono.Name}</h5>
                            <p>{telefono.Phone}</p>
                        </div>
                    </div>
                )
            )
        } catch (e) {
            return (this.state.loader)
        }
    }

    render() {
        if (this.state.return_redirect) return <Redirect to='/telefono' push />
        if (this.state.is_error) return (
            <>
                {this.state.notification_message}
            </>
        )

        return (
            <div>
                <h1 className="text-center mt-4 mb-5 pt-4 pb-5">Telefonos</h1>
                {
                    this.state.is_notification ? (
                        <div className='row mt-3 mb-3'>
                            <div className='col-md-12'>
                                {this.state.notification_message}
                            </div>
                        </div>
                    ) : null
                }
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="phone_number">Número de teléfono</label>
                        <input type="text" 
                            onChange={(val) => {
                             
                                this.setState({
                                    phone_validacion: true
                                })
                                this.setState(prevState => {
                                    let telefono_values = Object.assign({}, prevState.telefono_values);
                                    telefono_values.Phone = val.target.value;
                                    return { telefono_values };
                                })
                                this.setState({
                                    telefonoPhone: val.target.value
                                })
                            }} defaultValue={this.state.telefono_values.Phone}
                            className={"form-control" + ((this.state.telefono_values.Phone.length === 0 && this.state.phone_validacion) ? ' is-invalid' : '')} 
                            id="phone_number" 
                            placeholder="Ej. +50212345678" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="nombre">Nombre</label>
                        <input type="text" 
                            onChange={(val) => {
                                this.setState({
                                    name_validation: true
                                }) 
                                this.setState(prevState => {
                                    let telefono_values = Object.assign({}, prevState.telefono_values);
                                    telefono_values.Name = val.target.value;              
                                    return { telefono_values };
                                })
                                this.setState({
                                    telefonoName: val.target.value
                                })
                            }} 
                            defaultValue={this.state.telefono_values.Name} 
                            className={"form-control" + ((this.state.telefono_values.Name.length === 0 && this.state.name_validation) ? ' is-invalid' : '')} 
                            id="nombre" 
                            placeholder="Ej. Numero de cemaco" />
                    </div>
                </div>
                <button type="button" className="btn btn-primary float-right" disabled={this.state.telefono_values.Phone.length === 0 || this.state.telefono_values.Name.length === 0} onClick={() => {
                    Auth.currentSession().then(session=>{

                        var token = session.getIdToken().getJwtToken()
                        var headers = {"Authorization": 'Bearer'+' '+  token}
                        axios.post(
                            
                            `${process.env.REACT_APP_API_URL}/f/telefono`, {
                                'Phone': this.state.telefonoPhone,
                                'Name': this.state.telefonoName
                            },
                            {headers}
                        ).then(data => {
                            this.setState({ 
                                is_notification: true,
                                notification_message: (
                                    <div className="alert alert-success" role="alert">
                                        {data.data.message}
                                    </div>
                                )
                            })
                            this.loadTelefonos()
                        }).catch(error => {
                            this.setState({
                                is_notification: true,
                                notification_message: (
                                    <div className="alert alert-success" role="alert">
                                        {error.response.data.message}
                                    </div>
                                )
                            })
                        })
                    })
                    this.setState({
                        telefono_values: {
                            Phone: '',
                            Name: ''
                        }
                    })
                    this.setState({
                        phone_validacion: false,
                        name_validation: false
                    })
                }}>
                    Crear
                </button>
                
                {this.state.loading ? <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>: ''}

                <div className="row mt-5">
                    {this.state.loading ? '': !this.state.template_id && this.renderAllList()}
                </div>
            </div>
        )
    }
}

export default Telefono