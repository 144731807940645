import React, { Component } from "react";
import {
    Redirect
} from 'react-router-dom'
import CemacoLogo from "../cemaco-logo";
import { Auth } from 'aws-amplify'

class ForgotPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            codigo: '',
            newPassword: '',
            checkPassword: '',
            codigoHasError: false,
            newPasswordHasError: false,
            checkPasswordHasError: false,
            codigoInvalidText: '',
            newPasswordInvalidText: '',
            checkPasswordInvalidText: '',
            isLoadingButton: false,
            showNotification: false,
            notificationIsSuccess: false,
            notificationMessage: '',
            resetButtonDisabled: false,
            pageToRedirect: '',
            shouldRedirect: false,
            correo: '',
            correoHasError: false,
            correoInvalidText: '',
            correoEnviado: false,
            sendCodeButtonDisabled: false,
            puedeIniciarSesion: false,
            enviarALogin: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.enviarCodigo = this.enviarCodigo.bind(this)
        this.reset = this.reset.bind(this)
    }

    /**
     * Carga la información del paso actual el su variable de estado
     * @param {*} e Evento del boton
     */
    handleChange(e) {
        this.setState({
            codigo: (e.target.id === 'codigo') ? e.target.value : this.state.codigo,
            newPassword: (e.target.id === 'new-password') ? e.target.value : this.state.newPassword,
            checkPassword: (e.target.id === 'check-new-password') ? e.target.value : this.state.checkPassword,
            correo: (e.target.id === 'correo') ? e.target.value : this.state.correo
        })
        if (e.target.id === 'codigo') {
            this.setState({
                codigoHasError: e.target.value.length === 0,
                codigoInvalidText: e.target.value.length === 0 ? 'La contraseña anterior es requerida' : '',
                resetButtonDisabled: e.target.value.length === 0
            })
        } else if (e.target.id === 'new-password') {
            this.setState({
                newPasswordHasError: e.target.value.length === 0,
                newPasswordInvalidText: e.target.value.length === 0 ? 'La contraseña nueva es requerida' : '',
                resetButtonDisabled: e.target.value.length === 0
            })
        } else if (e.target.id === 'check-new-password') {
            this.setState({
                checkPasswordHasError: e.target.value.length === 0,
                checkPasswordInvalidText: e.target.value.length === 0 ? 'Debes volver a escribir tu contraseña nueva' : '',
                resetButtonDisabled: e.target.value.length === 0
            })
        } else if (e.target.id === 'correo') {
            this.setState({
                correoHasError: e.target.value.length === 0,
                correoInvalidText: e.target.value.length === 0 ? 'Debes ingresar tu correo electronico' : '',
                sendCodeButtonDisabled: e.target.value.length === 0
            })
        }
    }

    /**
     * Se encarga de ejecutar el cambio de la contraseña
     */
    async reset() {
        this.setState({
            showNotification: false,
            resetButtonDisabled: true
        })
        if (this.state.codigo.length === 0 || this.state.newPassword.length === 0 || this.state.checkPassword.length === 0) {
            this.setState({
                codigoHasError: this.state.codigo.length === 0,
                codigoInvalidText: this.state.codigo.length === 0 ? 'El codigo de verificacion es requerido' : '',
                newPasswordHasError: this.state.newPassword.length === 0,
                newPasswordInvalidText: this.state.newPassword.length === 0 ? 'La contraseña nueva es requerida' : '',
                checkPasswordHasError: this.state.checkPassword.length === 0,
                checkPasswordInvalidText: this.state.checkPassword.length === 0 ? 'Debes volver a escribir tu contraseña nueva' : '',
                resetButtonDisabled: this.state.codigo.length === 0 || this.state.newPassword.length === 0 || this.state.checkPassword.length === 0,
                notificationIsSuccess: false,
                notificationMessage: 'Todos los campos son requeridos',
                showNotification: true
            })
        } else {
            try {
                Auth.forgotPasswordSubmit(this.state.correo, this.state.codigo, this.state.newPassword)
                .then(data => {
                    this.setState({
                        showNotification: true,
                        notificationIsSuccess: true,
                        notificationMessage: 'La contraseña se cambio con éxito, ya puedes iniciar sesion',
                        puedeIniciarSesion: true
                    })
                }).catch(error => {
                    this.setState({
                        showNotification: true,
                        notificationIsSuccess: false,
                        notificationMessage: 'Hubo un error actualizando su contraseña'
                    })
                }).finally(() => {
                    this.setState({
                        resetButtonDisabled: false
                    })
                })
            } catch (e) {
                switch (e.code) {
                    case "UserNotFoundException":
                        this.setState({
                            notificationMessage: "El usuario o la contraseña son incorrectos"
                        })
                        break;
                    default:
                        this.setState({
                            notificationMessage: e.message
                        })
                        break;
                }
                this.setState({
                    notificationIsSuccess: false,
                    showNotification: true,
                    resetButtonDisabled: false
                })
            }
        }
    }

    /**
     * Envia el codigo de cambio de contraseña por correo al usuario que lo solicito
     */
    enviarCodigo() {
        if (this.state.correo.length > 0) {
            this.setState({
                sendCodeButtonDisabled: true,
                showNotification: false
            })
            Auth.forgotPassword(this.state.correo)
            .then(data => {
                this.setState({
                    showNotification: true,
                    notificationIsSuccess: true,
                    notificationMessage: 'Se envio un correo con un codigo de verificacion al correo ' + this.state.correo,
                    correoEnviado: true
                })
            }).catch(error => {
                this.setState({
                    showNotification: true,
                    notificationIsSuccess: false,
                    notificationMessage: error.message
                })
            }).finally(() => {
                this.setState({
                    sendCodeButtonDisabled: false
                })
            })
        }
    }

    render() {
        if (this.state.enviarALogin) return <Redirect to='/login' push />
        let new_password_form = (
            <>
                <div className='form-group mb-3'>
                    <input type='text' className={'form-control ' + (this.state.codigoHasError ? 'is-invalid' : '')} id='codigo' onChange={this.handleChange} value={this.state.codigo} placeholder='Codigo de confirmacion' />
                    <div className={'invalid-feedback ' + (!this.state.codigoHasError ? 'd-none' : '')}>
                        {this.state.codigoInvalidText}
                    </div>
                </div>
                <div className='form-group mb-3'>
                    <input type='password' className={'form-control ' + (this.state.newPasswordHasError ? 'is-invalid' : '')} id='new-password' onChange={this.handleChange} value={this.state.newPassword} placeholder='Contraseña nueva' />
                    <div className={'invalid-feedback ' + (!this.state.newPasswordHasError ? 'd-none' : '')}>
                        {this.state.newPasswordInvalidText}
                    </div>
                </div>
                <div className='form-group mb-3'>
                    <input type='password' className={'form-control ' + (this.state.checkPasswordHasError ? 'is-invalid' : '')} id='check-new-password' onChange={this.handleChange} value={this.state.checkPassword} placeholder='Verifica tu contraseña' />
                    <div className={'invalid-feedback ' + (!this.state.checkPasswordHasError ? 'd-none' : '')}>
                        {this.state.checkPasswordInvalidText}
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        {
                            this.state.puedeIniciarSesion ? (
                                <button className="btn btn-primary cemaco-button" type="submit" onClick={() => {
                                    this.setState({
                                        enviarALogin: true
                                    })
                                }}>
                                    Volver al login
                                </button>
                            ) : (
                                <button className={"btn btn-primary cemaco-button " + (this.state.resetButtonDisabled ? 'disabled' : '')} type="submit" onClick={this.reset}>
                                    Cambiar contraseña
                                </button>
                            )
                        }
                        
                    </div>
                </div>
            </>
        )
        let mail_to_reset = (
            <>
                <div className='form-group mb-3'>
                    <input type='mail' className={'form-control ' + (this.state.correoHasError ? 'is-invalid' : '')} id='correo' onChange={this.handleChange} value={this.state.correo} placeholder='Correo electronico' />
                    <div className={'invalid-feedback ' + (!this.state.correoHasError ? 'd-none' : '')}>
                        {this.state.correoInvalidText}
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <button className={"btn btn-primary cemaco-button " + (this.state.sendCodeButtonDisabled ? 'disabled' : '')} type="submit" onClick={this.enviarCodigo}>Enviar Codigo</button>
                    </div>
                </div>
            </>
        )
        if (this.state.shouldRedirect) return <Redirect to={this.state.pageToRedirect} push />
        return (
            <div className="container">
                <div className='row mt-5'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='card bg-light text-center'>
                            <div className='card-body'>
                                <CemacoLogo textColor='#101e8e' />
                                <h5 className='card-title mb-3 mt-2'>
                                    <small className='text-muted'>Cambiar mi contraseña</small>
                                </h5>
                                <div className='row'>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-8'>
                                        <div className={'alert ' + (this.state.notificationIsSuccess ? 'alert-success' : 'alert-danger') + ' ' + (this.state.showNotification ? '' : 'd-none')}>
                                            {this.state.notificationMessage}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-8'>
                                        {
                                            this.state.correoEnviado ? new_password_form : mail_to_reset
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword