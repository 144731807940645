import { Link, Redirect } from "react-router-dom";
import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";

class usuarioCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      nombreCompleto: "",
      grupos_info: [],
      valueSelect: "admin",
      is_notifiaction: false,
      errorMensaje: "",
      redirect: false,
    };
    this.enviandoFormulario = this.enviandoFormulario.bind(this);
    this.crearUsuarioCognito = this.crearUsuarioCognito.bind(this);
  }
  componentDidMount() {
    this.lisgarGrupos();
  }
  lisgarGrupos() {
    Auth.currentSession().then((session) => {
      var token = session.getIdToken().getJwtToken();
      var headers = { Authorization: "Bearer" + " " + token };
      axios
        .get(`${process.env.REACT_APP_API_URL}/f/users/groups`, { headers })
        .then((data) => {
          this.setState({
            grupos_info: data.data,
          });
        });
    });
  }
  renderListarGrupos() {
    return (
      <div className="form-group">
        <label htmlFor="selectGrupo">Seleccione una opcion</label>

        <select
          className="form-control"
          onChange={async (event) => {
            this.setState({
              valueSelect: event.target.value,
            });
          }}
          id="selectGrupo"
        >
          {this.state.grupos_info.map((data, index) => (
            <option key={index} value={data.Nombre}>
              {data.Nombre}
            </option>
          ))}
        </select>
      </div>
    );
  }

  crearUsuarioCognito() {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        var token = session.getIdToken().getJwtToken();
        var headers = { Authorization: "Bearer" + " " + token };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/f/users`,
            {
              username: this.state.username,
              email: this.state.email,
            },
            { headers }
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            this.setState({
              errorMensaje: error.response.data.message,
              is_notifiaction: true,
            });
          });
      });
    });
  }
  asignarGrupoUsuario(usuario) {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        var token = session.getIdToken().getJwtToken();
        var headers = { Authorization: "Bearer" + " " + token };
        let nombre = usuario.replace("_", "");
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/f/users/grupos`,
            {
              username: usuario,
              email: this.state.email,
              grupo: this.state.valueSelect,
            },
            { headers }
          )
          .then((resp) => {
            resolve(resp.status);
          })
          .catch((error) => {
            this.setState({
              errorMensaje: error.response.data.message,
              is_notifiaction: true,
            });
            reject(400) 
          });
      });
    });
  }
  enviandoFormulario = async (e) => {
    e.preventDefault();
    const crearUsuarioRespuesta = await this.crearUsuarioCognito();
    if(crearUsuarioRespuesta.status===200)
    {
        const asignarGrupoRespuesta = await this.asignarGrupoUsuario(crearUsuarioRespuesta.data.username);
        if(asignarGrupoRespuesta===200)
        {
            this.setState({
                redirect: true,
            });
        }
    }
   
    
  };
  render() {
    if (this.state.redirect) return <Redirect to="/Usuarios" push />;
    try {
      return (
        <div>
          {this.state.is_notifiaction ? (
            <div className="alert alert-danger" role="alert">
              {this.state.errorMensaje}
            </div>
          ) : null}
          <form onSubmit={this.enviandoFormulario}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  this.setState({
                    username: e.target.value,
                  });
                }}
                defaultValue={this.state.username}
                id="username"
                aria-describedby="usernameHelper"
                placeholder="Ingrese usuario"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Correo Electronico</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
                defaultValue={this.state.email}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
            </div>

            <div className="form-group">{this.renderListarGrupos()}</div>

            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
          </form>
        </div>
      );
    } catch (e) {
      return e;
    }
  }
}

export default usuarioCreate;
