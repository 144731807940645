import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CemacoLogo from "./cemaco-logo";
import { Auth } from "aws-amplify";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

class Structure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            sesionVerificada: false,
            permissions: {},
            all_permissions:false
        };
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        
        Auth.currentSession()
        
            .then((data) => {
                this.setState({
                    isLoggedIn: true,
                    sesionVerificada: true,
                });
      
                var headers = {"Authorization": 'Bearer' + ' ' + data.getIdToken().getJwtToken()}
               
                axios.get(`${process.env.REACT_APP_API_URL}/f/permisos`,{headers}).then(response=>{
                    if(response.status===200){
                       
                        if(response.data.permissions==="*"){
                            
                            this.setState({all_permissions:true})
                        }
                        else
                            
                            this.setState({permissions:response.data.permissions})
                    }
                        
                }).catch(ex=>{})
            })
            .catch((error) => {
             
                this.setState({
                    isLoggedIn: false,
                    sesionVerificada: true,
                });
            });
        
    }

    async logout() {
        Auth.signOut({ global: true }).then((data) => {
            this.setState({
                isLoggedIn: false,
            });
        });
    }

    render() {
        if (!this.state.sesionVerificada) {
            return <h4>Verificando su su sesion</h4>;
        } else {
                  
            if (!this.state.isLoggedIn) return <Redirect to="/login" push />;
            return (
                <div>
                    <nav
                        className="navbar navbar-expand-lg navbar-dark"
                        style={{
                            backgroundColor: "#101E8E",
                        }}
                    >
                        <Link to="/" className="navbar-brand">
                            <CemacoLogo textColor="#fff" />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    {
                                    
                                        this.state.permissions["ENVIAR_MENSAJE_MASIVO"] || this.state.all_permissions?
                                        <Link
                                            to="/enviar-mensajes"
                                            className="nav-link active"
                                        >
                                            
                                            Mensajes
                                        </Link>:null
                                        
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        this.state.permissions["ENVIAR_MENSAJE_INDIVIDUAL"] || this.state.all_permissions?
                                        <Link
                                            to="/mensaje-individual"
                                            className="nav-link active"
                                        >
                                            Mensaje individual
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        this.state.permissions["CREAR_TEMPLATE"] || this.state.all_permissions?
                                        <Link
                                            to="/template"
                                            className="nav-link active"
                                        >
                                            Templates
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        this.state.permissions["CREAR_NUMERO_TELEFONO"] || this.state.all_permissions?
                                        <Link
                                            to="/telefono"
                                            className="nav-link active"
                                        >
                                            Numeros de telefono
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        this.state.permissions["VER_LOGS"] || this.state.all_permissions?
                                        <Link
                                            to="/logs"
                                            className="nav-link active"
                                        >
                                            Logs
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        this.state.permissions["VER_LOGSPROGRAMADOS"] || this.state.all_permissions?
                                        <Link
                                            to="/logsmensajesprogramados"
                                            className="nav-link active"
                                        >
                                            Logs Mensajes Programados
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        (this.state.permissions["VER_SUPLEMENTO"] || this.state.all_permissions)?
                                        <Link
                                            to="/suplemento"
                                            className="nav-link active"
                                        >
                                            Suplemento
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                               
                                    {   
                                        
                                        (this.state.permissions["VER_LOGS"] || this.state.all_permissions)?
                                        <Link
                                            to="/archivos"
                                            className="nav-link active"
                                        >
                                           
                                            Archivos
                                        </Link>:null
                                    }
                                </li>
                                <li className="nav-item">
                                    {
                                        (this.state.permissions["USUARIOS"] || this.state.all_permissions)?
                                        <Link
                                            to="/Usuarios"
                                            className="nav-link active"
                                        >
                                            
                                            Usuarios
                                        </Link>:null
                                    }
                                </li>
                            </ul>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item dropdown">
                                    <button
                                        className="btn btn-link nav-link dropdown-toggle"
                                        id="navbarDropdown"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon
                                            role="button"
                                            icon={faUser}
                                        />
                                    </button>
                                    <div
                                        className="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        {
                                        (this.state.permissions["GENERAR_TOKEN"] || this.state.all_permissions)?
                                         <Link
                                            to="/auth/token"
                                        >
                                            <button
                                            className="btn btn-link dropdown-item">
                                            Generar Token
                                            </button>
                                        </Link>:null
                                        }
                                        <button
                                            className="btn btn-link dropdown-item"
                                            onClick={this.logout}
                                        >
                                            Cerrar sesion
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <div className={this.props.container}>
                        {
                            React.Children.map(this.props.children,child=>{
                                 
                                if(Object.keys(this.state.permissions).length>0 || this.state.all_permissions)
                                    return React.cloneElement(child,{permissions:this.state.permissions,all_permissions:this.state.all_permissions})
                                return null
                            })
                        }
                    </div>
                </div>
            );
        }
    }
}

export default Structure;
